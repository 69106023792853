import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonSegment,
  IonSegmentButton,
  IonRadioGroup,
  IonRadio,
  IonAccordionGroup,
  IonList,
  IonAccordion,
  IonButtons,
  IonIcon,
  IonButton,
  IonBadge,
  IonSplitPane,
  IonMenu,
  IonRouterLink,
  IonRippleEffect,
  IonRouterOutlet,
  NavContext,
  useIonViewDidLeave,
} from "@ionic/react";

import {
  Datepicker,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import Button from "./../../components/Ebert/Button";

import {
  Route,
  RouteComponentProps,
  useHistory,
  useParams,
} from "react-router";

type UserProps = {
  firstname: string;
  lastname: string;
};

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const Notes: React.FC = () => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  type NotesProps = {
    id: number;
    note: string;
    buttonTime: string;
    buttonText: string;
    date: string;
  };

  const [notes, setNotes] = useState<NotesProps[]>([
    {
      id: 1,
      note: "Notatka 1",
      buttonTime: "1:26:23",
      buttonText: "Matematyka - Podstawy geometrii",
      date: "11:28 14-02-2022",
    },
    {
      id: 2,
      note: "Notatka 2",
      buttonTime: "1:26:23",
      buttonText: "Matematyka - Podstawy geometrii",
      date: "11:28 14-02-2022",
    },
    {
      id: 3,
      note: "Notatka 3",
      buttonTime: "1:26:23",
      buttonText: "Matematyka - Podstawy geometrii",
      date: "11:28 14-02-2022",
    }
  ]);

  return (
    <div className="px-5 pt-5">
                      {notes.length > 0 ? (
                        <>
                          <div >
                            <IonList
                              lines="none"
                              style={{
                                padding: "8px 15px",
                                maxHeight: "MIN( 1200px, 80vh )",
                                overflow: "auto",
                                background: "white",
                              }}
                              className="rounded"
                            >
                              {notes.map((e) => {
                                return (
                                  <IonItem
                                    style={{
                                      borderRadius: "14px",
                                      margin: "10px 0",
                                      border:
                                        "var(--ion-shadow-border-primary)",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <div className="row">
                                        <div
                                          className="col-auto"
                                          style={{
                                            margin: "0 14px",
                                            fontSize: "18px",
                                            marginTop: "7px",
                                          }}
                                        >
                                          {e.note.length > 500
                                            ? e.note.substring(0, 500) + "..."
                                            : e.note}
                                        </div>
                                        {e.note.length > 500 ? (
                                          <div className="row justify-content-center">
                                            <div className="col-auto ">
                                              <Button
                                                height={35}
                                                style={{
                                                  fontWeight: "600",
                                                  display: "block",
                                                }}
                                                fill="clear"
                                                color="secondary"
                                              >
                                                Zobacza więcej
                                              </Button>
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                      <div className="row justify-content-between">
                                        <div className="col-auto">
                                          <Button
                                            height={20}
                                            fontSize={13}
                                            fill="clear"
                                          >
                                            <strong>{e.buttonTime}</strong>
                                            &nbsp;|&nbsp;
                                            {e.buttonText}
                                          </Button>
                                        </div>
                                        <div
                                          className="col-auto text-right"
                                          style={{
                                            display: "inline-flex",
                                          }}
                                        >
                                          <Button
                                            height={20}
                                            fontSize={13}
                                            style={{
                                              fontWeight: "700",
                                            }}
                                            className="show-on-hover"
                                            fill="clear"
                                            color="danger"
                                            onClick={() => {
                                              setNotes(
                                                notes.filter(function (obj) {
                                                  return obj.id !== e.id;
                                                })
                                              );
                                            }}
                                            label="USUŃ NOTATKĘ"
                                          ></Button>
                                          <Button
                                            height={20}
                                            fontSize={13}
                                            style={{
                                              fontWeight: "700",
                                              pointerEvents: "none",
                                            }}
                                            fill="clear"
                                            color="medium"
                                          >
                                            {e.date}
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </IonItem>
                                );
                              })}
                            </IonList>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
  );
};

export default Notes;
