import { localePl, Popup, setOptions } from "@mobiscroll/react";
import React, {
  createRef,
  MouseEvent,
  Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { IonIcon, isPlatform } from "@ionic/react";
// import {
//   BarcodeScanner,
//   BarcodeScannerOptions,
//   BarcodeScanResult,
// } from "@ionic-native/barcode-scanner";
import { useHistory, useLocation } from "react-router";

import { useGlobalState, GlobalStateInterface } from "../GlobalStateProvider";

import { Storage } from "@ionic/storage";

import MobiPopup from "./Mobi/MobiPopup";
import { NavLink } from "react-router-dom";

setOptions({
  theme: "ios",
  themeVariant: "light",
  locale: localePl,
});

type Props = {
  margin?: boolean;
};

const Footer: React.FC<Props> = ({ margin = true }) => {
  return isPlatform("mobile") ? (
    <></>
  ) : (
    <footer className="p-4 pt-8 pt-md-11 position-relative bg-darken dark-70 bg-gigas">
      {/* Shape */}
      <div className="d-none d-wd-block shape shape-blur shape-left shape-flip-one-side shape-fluid-y svg-shim text-white">
        <svg
          viewBox="0 0 310 800"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
        >
          <path
            fill="currentColor"
            d="M193,0H0v800h310c-64.6,0-117-52.4-117-117V0z"
          />
        </svg>
      </div>
      <div className="container">
        <div className="row justify-content-center" id="accordionFooter">
          <div className="col-12 col-md-4 col-lg-4">
            {/* Brand */}
            <img
              src="./assets/img/brand-gigas.svg"
              alt="..."
              className="footer-brand img-fluid mb-4 h-60p"
            />
            {/* Text */}
            <p className="text-gray-800 mb-4 font-size-sm-alone">
              Będzin 42-500
              <br />
              ul. Piłsudskiego 40
            </p>
            <div className="mb-4">
              <a
                href="mailto:info@experientia.pl"
                className="text-gray-800 font-size-sm-alone"
              >
                info@experientia.pl
              </a>
            </div>
            {/* Social */}
            <ul className="list-unstyled list-inline list-social mb-4 mb-md-0 mx-n2 text-white-70 font-size-sm">
              <li className="list-inline-item list-social-item">
                <a
                  href="#"
                  className="w-36 h-36 bg-white-hover rounded-circle d-flex align-items-center justify-content-center"
                >
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li className="list-inline-item list-social-item">
                <a
                  href="#"
                  className="w-36 h-36 bg-white-hover rounded-circle d-flex align-items-center justify-content-center"
                >
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li className="list-inline-item list-social-item">
                <a
                  href="#"
                  className="w-36 h-36 bg-white-hover rounded-circle d-flex align-items-center justify-content-center"
                >
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li className="list-inline-item list-social-item">
                <a
                  href="#"
                  className="w-36 h-36 bg-white-hover rounded-circle d-flex align-items-center justify-content-center"
                >
                  <i className="fab fa-linkedin-in" />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-md-2 col-lg-2">
            <div className="mb-5 mb-xl-0 footer-accordion">
              <h5 className="mb-3">
                <button
                  className="text-dark fw-medium footer-accordion-toggle d-flex align-items-center"
                  type="button"
                >
                  Strona główna
                </button>
              </h5>
              <h5 className="mb-3">
                <button
                  className="text-dark fw-medium footer-accordion-toggle d-flex align-items-center"
                  type="button"
                >
                  Nasze kursy
                </button>
              </h5>
              <h5 className="mb-3">
                <button
                  className="text-dark fw-medium footer-accordion-toggle d-flex align-items-center"
                  type="button"
                >
                  Korepetycje
                </button>
              </h5>
              <h5 className="mb-3">
                <button
                  className="text-dark fw-medium footer-accordion-toggle d-flex align-items-center"
                  type="button"
                >
                  Nasze kursy
                </button>
              </h5>
            </div>
          </div>

          <div className="col-6 col-md-2 col-lg-2">
            <div className="mb-5 mb-xl-0 footer-accordion">
              <h5 className="mb-3">
                <button
                  className="text-dark fw-medium footer-accordion-toggle d-flex align-items-center"
                  type="button"
                >
                  Logowanie
                </button>
              </h5>
              <h5 className="mb-3">
                <button
                  className="text-dark fw-medium footer-accordion-toggle d-flex align-items-center"
                  type="button"
                >
                  Rejestracja
                </button>
              </h5>
              <h5 className="mb-3">
                <button
                  className="text-dark fw-medium footer-accordion-toggle d-flex align-items-center"
                  type="button"
                >
                  Profil
                </button>
              </h5>
              <h5 className="mb-3">
                <button
                  className="text-dark fw-medium footer-accordion-toggle d-flex align-items-center"
                  type="button"
                >
                  Twoje kursy
                </button>
              </h5>
              <h5 className="mb-3">
                <button
                  className="text-dark fw-medium footer-accordion-toggle d-flex align-items-center"
                  type="button"
                >
                  Twoje korepetycje
                </button>
              </h5>
            </div>
          </div>

          <div className="col-12 col-md-2 col-lg-2">
            <div className="mb-5 mb-xl-0 footer-accordion">
              <h5 className="mb-3">
                <button
                  className="text-dark fw-medium footer-accordion-toggle d-flex align-items-center"
                  type="button"
                >
                  Regulamin
                </button>
              </h5>
              <h5 className="mb-3">
                <button
                  className="text-dark fw-medium footer-accordion-toggle d-flex align-items-center"
                  type="button"
                >
                  Polityka prywatności
                </button>
              </h5>
            </div>
          </div>
        </div>{" "}
        {/* / .row */}
        <div className="py-8 text-center">
          <p className="text-gray-800 font-size-sm-alone mb-0 px-5 px-md-0">
            Copyright © 2021 Experientia sp. z o.o.
            <br /> Wszelkie prawa zastrzeżone.
          </p>
        </div>
      </div>{" "}
      {/* / .container */}
    </footer>
  );
};

export default Footer;
