import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonSegment,
  IonSegmentButton,
  IonRadioGroup,
  IonRadio,
  IonAccordionGroup,
  IonList,
  IonAccordion,
  IonButtons,
  IonIcon,
  IonButton,
  IonBadge,
  IonCheckbox,
  IonRouterLink,
} from "@ionic/react";

import {
  Datepicker,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
} from "react";

import Button from "./../components/Ebert/Button";

import Header from "../components/JobsterHeader";
import ContentBox from "../components/ContentBox";

import { useDebounce, useDebouncedCallback } from "use-debounce";

import { Main } from "../components/HomeScript";
import { isPlatform, ScrollDetail } from "@ionic/core";

import MobiInput from "../components/Mobi/MobiInput";
import { LocationModelAttributes } from "../components/Order/Location";
import { isValidPostCode, onScrollHeader } from "../components/Common";
import MobiPopup from "../components/Mobi/MobiPopup";

import "./Login.scss";

import JobPost from "../components/Home/JobPost";

import Footer from "../components/Footer";
import { useHistory } from "react-router";
import MobiButton from "../components/Mobi/MobiButton";

import { Link } from "react-router-dom";
import CustomHeaderFade from "../components/CustomHeaderFade";
import CustomHeaderCondense from "../components/CustomHeaderCondense";
import MobiTextarea from "../components/Mobi/MobiTextarea";
import {
  cartOutline,
  contractOutline,
  layersOutline,
  personCircle,
} from "ionicons/icons";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";

import { Storage } from '@ionic/storage';
import HeaderToolbarMobile from "../components/Header/HeaderToolbarMobile";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const Home: React.FC = () => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  const [segmentValue, setSegmentValue] = useState("login");

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);

  const [loginData, setLoginData] = useState({
    email: "",
    password: ""
  });


  const segmentContainerRef = useRef<HTMLDivElement>(null);
  const ionSegmentRef = useRef<HTMLIonSegmentElement>(null);
  const ionSegmentHeaderRef = useRef<HTMLIonSegmentElement>(null);
  const ionHeaderRef = useRef<HTMLIonHeaderElement>(null);

  const [checked, setChecked] = useState(false);

  const { state, setState } = useGlobalState();
  const storage = new Storage();

  useEffect(() => {

    segmentContainerRef.current?.querySelectorAll(".segment-single").forEach((_e) => {
      const parsed = _e as HTMLDivElement;
      parsed.style.display = "";
    });

    const e = segmentContainerRef.current?.querySelector(".segment-single" + "." + segmentValue) as HTMLDivElement;

    console.log(e)

    if(e)
    {
      e.style.display = "block";
    }

    

  }, [])

  return (
    <IonPage>
      <CustomHeaderFade
        ionHeaderRef={ionHeaderRef}
        title="Lista zleceń"
        logo
        extraButtons={
          <HeaderToolbarMobile />
        }
        segment={
          isPlatform("mobile") || isPlatform("tablet") ? (
            <IonToolbar>
              <IonSegment
                ref={ionSegmentHeaderRef}
                value={segmentValue}
                className="px-3"
                mode="md"
                onScroll={(event) => {
                  const target = event.target as HTMLIonSegmentElement;

                  if (target) {
                    ionSegmentRef.current?.scrollTo(target.scrollLeft, 0);
                  }

                  console.log(target.scrollLeft);
                }}
                onIonChange={(event) => {
                  setSegmentValue(
                    event.detail.value ? event.detail.value : "login"
                  );

                  if (segmentContainerRef.current) {
                    segmentContainerRef.current
                      .querySelectorAll(".segment-single")
                      .forEach((_e) => {
                        const parsed = _e as HTMLDivElement;
                        parsed.style.display = "";
                      });

                    const e = segmentContainerRef.current.querySelector(
                      ".segment-single" + "." + event.detail.value
                    ) as HTMLDivElement;

                    if (e) {
                      e.style.display = "block";
                    }

                    segmentContainerRef.current
                      ?.querySelector("#under-segment")
                      ?.scrollIntoView({ block: "start" });
                  }
                }}
              >
                <IonSegmentButton value="login">
                  <IonLabel>Logowanie</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="register">
                  <IonLabel>Rejestracja</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonToolbar>
          ) : (
            <></>
          )
        }
      />

      <IonContent id="login-page-controller" className="background-lightgrey">
        <div
          className="container pb-4 pb-xl-7 d-lg-flex align-items-center pt-0 pt-md-6 pt-lg-0"
          style={{
            minHeight: "calc( 100vh - 108px - 467px )",
          }}
        >
          <div className="row w-100pr " ref={segmentContainerRef}>
            <div className="col-12 col-lg-6 p-6 segment-single login d-lg-flex align-items-lg-center">
              <div style={{ width: "100%" }}>
                <div className="row">
                  <h2>Logowanie</h2>
                </div>

                <div className="row mt-2">
                  <div className="col-12 col-lg-9">
                    <div className="row">
                      <MobiInput
                        label="email"
                        required
                        value={loginData.email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setLoginData({ ...loginData, email: e.target.value });
                          // setPhoneError("");
                        }}
                      />
                    </div>
                    <div className="row mt-4">
                      <MobiInput
                        label="hasło"
                        required
                        type="password"
                        passwordToggle={true}
                      />
                    </div>
                    <div className="row justify-content-end">
                      <div className="col-auto">
                        <Button
                          routerLink="/login/recovery"
                          color="primary"
                          fill="clear"
                          style={{ width: "145px" }}
                          height={20}
                          label="Przypomnij hasło"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 col-lg-9">
                    <div className="row justify-content-center">
                      <div className="col-10">
                        <Button
                          routerLink="/profile/data"
                          loading={loadingLogin}
                          style={{ width: "100%" }}
                          label="ZALOGUJ"
                          onClick={async () => {
                            setState((prev) => ({
                              ...prev,
                              ...{
                                firstname: loginData.email,
                                logged: true,
                                token: "admin",
                              },
                            }));
                            await storage.create();
                            await storage.set('token', 'admin');
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 p-6 segment-single register">
              <div className="row">
                <h2>Rejestracja</h2>
              </div>

              <div className="row mt-2">
                <div className="col-12 col-lg-6">
                  <MobiInput label="Imię" required />
                </div>
                <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                  <MobiInput label="Nazwisko" required />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <MobiInput label="Adres email" required />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <MobiInput label="Numer telefonu" required />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <IonItem lines="none">
                    <IonLabel className="checkbox-longtext">
                      Zgadzam się z regulaminem Experientia
                    </IonLabel>
                    <IonCheckbox
                      slot="start"
                      checked={checked}
                      onIonChange={(e) => setChecked(e.detail.checked)}
                    />
                  </IonItem>
                </div>
              </div>
              <div className="row justify-content-center mt-5">
                <div className="col-8">
                  <Button
                    routerLink="/register-success"
                    disabled={!checked}
                    loading={loadingRegister}
                    style={{ width: "100%" }}
                    label="STWÓRZ KONTO"
                    onClick={() => {
                      setLoadingRegister(true);

                      setTimeout(() => {
                        setLoadingRegister(false);
                      }, 2000);
                    }}
                  />
                </div>
              </div>
              <div className="row justify-content-center mt-1">
                <div className="col-8">
                  <Button
                    fill="clear"
                    style={{ width: "100%", fontSize: "15px" }}
                    label="ZOBACZ REGULAMIN"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
