import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonSegment,
  IonSegmentButton,
  IonRadioGroup,
  IonRadio,
  IonAccordionGroup,
  IonList,
  IonAccordion,
  IonButtons,
  IonIcon,
  IonButton,
  IonBadge,
  IonCheckbox,
  IonRouterLink,
} from "@ionic/react";

import {
  Datepicker,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
} from "react";

import Button from "./../components/Ebert/Button";

import { isPlatform, ScrollDetail } from "@ionic/core";

import MobiInput from "../components/Mobi/MobiInput";

import "./OrderStatus.scss";

import Footer from "../components/Footer";

import CustomHeaderFade from "../components/CustomHeaderFade";
import {
  cartOutline,
  chevronForwardOutline,
  contractOutline,
  layersOutline,
  personCircle,
} from "ionicons/icons";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";

import { Storage } from "@ionic/storage";
import HeaderToolbarMobile from "../components/Header/HeaderToolbarMobile";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const Home: React.FC = () => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  const [segmentValue, setSegmentValue] = useState("login");

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const segmentContainerRef = useRef<HTMLDivElement>(null);
  const ionSegmentRef = useRef<HTMLIonSegmentElement>(null);
  const ionSegmentHeaderRef = useRef<HTMLIonSegmentElement>(null);
  const ionHeaderRef = useRef<HTMLIonHeaderElement>(null);

  const [checked, setChecked] = useState(false);

  const { state, setState } = useGlobalState();
  const storage = new Storage();

  useEffect(() => {
    segmentContainerRef.current
      ?.querySelectorAll(".segment-single")
      .forEach((_e) => {
        const parsed = _e as HTMLDivElement;
        parsed.style.display = "";
      });

    const e = segmentContainerRef.current?.querySelector(
      ".segment-single" + "." + segmentValue
    ) as HTMLDivElement;

    console.log(e);

    if (e) {
      e.style.display = "block";
    }
  }, []);

  async function CreateStorage() {
    await storage.create();
  }

  return (
    <IonPage>
      <CustomHeaderFade
        ionHeaderRef={ionHeaderRef}
        title="Status zamówienia"
        
        extraButtons={<HeaderToolbarMobile />}
      />

      <IonContent
        id="order-status-page-controller"
        className="background-lightgrey"
      >
        <div className="container py-8 py-lg-11 woocommerce-order-received">
          <div className="row">
            <div className="col-xl-8 mx-xl-auto">
              <header className="entry-header">
                <h1 className="entry-title">Zamówienie opłacone</h1>
              </header>
              <div className="entry-content">
                <div className="woocommerce">
                  <div className="woocommerce-order">
                    <p className="woocommerce-notice woocommerce-notice--success woocommerce-thankyou-order-received">
                      Dziękujemy za kupno naszych kursów.
                    </p>
                    <ul className="woocommerce-order-overview woocommerce-thankyou-order-details order_details mb-4 mx-4">
                      <li className="woocommerce-order-overview__order order">
                        Numer zamówienia:
                        <strong>21706</strong>
                      </li>
                      <li className="woocommerce-order-overview__date date">
                        Data:
                        <strong>25.02.2022r.</strong>
                      </li>
                      <li className="woocommerce-order-overview__total total">
                        Kwota:
                        <strong>
                          <span className="woocommerce-Price-amount amount">
                            100,00 zł
                          </span>
                        </strong>
                      </li>
                    </ul>

                    <div className="col-12 py-5">
                      <div className="shadow-basic px-5 py-4 order-complete mx-4 mx-md-0">
                        <h3 className="mb-1 mx-3">Twoje zamówienie</h3>

                        {!Array.isArray(state.cart) || !state.cart.length ? (
                          <h4
                            className="mt-5 mx-3"
                            style={{
                              color: "var(--ion-color-medium)",
                              textAlign: "center",
                            }}
                          >
                            Nie posiadasz żadnych kursów w koszyku
                          </h4>
                        ) : (
                          <IonList>
                            <IonItem
                              lines="full"
                              style={{ fontWeight: 700, marginBottom: "10px" }}
                            >
                              <IonLabel color="secondary">Nazwa kursu</IonLabel>
                              <IonLabel
                                style={{ textAlign: "right" }}
                                color="secondary"
                              >
                                Cena
                              </IonLabel>
                            </IonItem>

                            {!Array.isArray(state.cart) ||
                            !state.cart.length ? (
                              <></>
                            ) : (
                              state.cart.map((e, i) => {
                                return (
                                  <IonItem
                                    className="course"
                                    lines={
                                      i == (state.cart as any).length - 1
                                        ? "full"
                                        : "none"
                                    }
                                    style={{
                                      fontWeight: 500,
                                      "--padding-bottom":
                                        i == (state.cart as any).length - 1
                                          ? "10px"
                                          : "",
                                    }}
                                  >
                                    <IonLabel color="medium">
                                      {e.category} {e.name}
                                    </IonLabel>
                                    <IonLabel color="medium">
                                      {e.price}
                                    </IonLabel>
                                  </IonItem>
                                );
                              })
                            )}

                            <IonItem lines="none" style={{ fontWeight: 700 }}>
                              <IonLabel color="secondary">Razem</IonLabel>
                              <IonLabel
                                style={{ textAlign: "right" }}
                                color="secondary"
                              >
                                100,00 zł
                              </IonLabel>
                            </IonItem>
                          </IonList>
                        )}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="row justify-content-end">
                        <div className="col-auto">
                        <Button className="mx-4 mx-md-0" fontSize={17} height={57} color="secondary" routerLink="/profile/courses" >
                          <span>Przejdź do swoich kursów</span>
                          <IonIcon style={{ marginLeft: "10px", marginTop: "-2px", width: "26px" }} src={chevronForwardOutline} />
                        </Button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
