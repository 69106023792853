import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonSegment,
  IonSegmentButton,
  IonRadioGroup,
  IonRadio,
  IonAccordionGroup,
  IonList,
  IonAccordion,
  IonButtons,
  IonIcon,
  IonButton,
  IonBadge,
  IonCheckbox,
  IonRouterLink,
} from "@ionic/react";

import {
  Datepicker,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
} from "react";

import Button from "./../components/Ebert/Button";

import { isPlatform, ScrollDetail } from "@ionic/core";

import MobiInput from "../components/Mobi/MobiInput";

import "./Cart.scss";

import Footer from "../components/Footer";

import CustomHeaderFade from "../components/CustomHeaderFade";
import {
  cartOutline,
  chevronForwardOutline,
  contractOutline,
  layersOutline,
  personCircle,
} from "ionicons/icons";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";

import { Storage } from "@ionic/storage";
import HeaderToolbarMobile from "../components/Header/HeaderToolbarMobile";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const Home: React.FC = () => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  const [segmentValue, setSegmentValue] = useState("login");

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const segmentContainerRef = useRef<HTMLDivElement>(null);
  const ionSegmentRef = useRef<HTMLIonSegmentElement>(null);
  const ionSegmentHeaderRef = useRef<HTMLIonSegmentElement>(null);
  const ionHeaderRef = useRef<HTMLIonHeaderElement>(null);

  const [checked, setChecked] = useState(false);

  const { state, setState } = useGlobalState();
  const storage = new Storage();

  useEffect(() => {
    segmentContainerRef.current
      ?.querySelectorAll(".segment-single")
      .forEach((_e) => {
        const parsed = _e as HTMLDivElement;
        parsed.style.display = "";
      });

    const e = segmentContainerRef.current?.querySelector(
      ".segment-single" + "." + segmentValue
    ) as HTMLDivElement;

    console.log(e);

    if (e) {
      e.style.display = "block";
    }
  }, []);

  async function CreateStorage() {
    await storage.create();
  }

  return (
    <IonPage>
      <CustomHeaderFade
        ionHeaderRef={ionHeaderRef}
        title="Koszyk"
        arrow
        extraButtons={<HeaderToolbarMobile />}
      />

      <IonContent id="cart-page-controller" className="background-lightgrey">
        <div
          className="container mt-0 mt-md-7 pb-6 pb-xl-10"
          style={{
            minHeight: "calc( 100vh - 108px - 467px )",
          }}
        >
          <div className="row">
            <div className="col-12 col-lg-8 px-8 py-5">
              <h3>Dane do płatności</h3>
              <div className="row mt-2">
                <div className="col-12 col-md-6">
                  <MobiInput label="Imię" required />
                </div>
                <div className="col-12 col-md-6 mt-4 mt-md-0">
                  <MobiInput label="Nazwisko" required />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <MobiInput label="Adres email" required />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <MobiInput label="Numer telefonu" required />
                </div>
              </div>
              <h3 className="mt-8">Adres do płatności</h3>
              <div className="row mt-2">
                <div className="col-12 col-md-6">
                  <MobiInput label="Miasto" required />
                </div>
                <div className="col-12 col-md-6 mt-4 mt-md-0">
                  <MobiInput label="Kod pocztowy" required />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12 col-md-7">
                  <MobiInput
                    label="Adres"
                    placeholder="Nazwa ulicy i numer domu"
                    required
                  />
                </div>
                <div className="col-12 col-md-5 mt-4 mt-md-0">
                  <MobiInput label="Numer mieszkania" required />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 py-5">
              <div className="shadow-basic px-5 py-4 order-complete mx-4">
                <h3 className="mb-1 mx-3">Twoje zamówienie</h3>
                
                {
                  !Array.isArray(state.cart) || !state.cart.length
                  ?
                  <h4 className="mt-5 mx-3" style={{ color: "var(--ion-color-medium)", textAlign: "center" }} >Nie posiadasz żadnych kursów w koszyku</h4>
                  :
<IonList>
                  <IonItem lines="full" style={{ fontWeight: 700, marginBottom: "10px" }}>
                    <IonLabel color="secondary">Nazwa kursu</IonLabel>
                    <IonLabel style={{ textAlign: "right" }} color="secondary">
                      Cena
                    </IonLabel>
                  </IonItem>

                  {!Array.isArray(state.cart) || !state.cart.length ? (
                    <></>
                  ) : (
                    state.cart.map((e, i) => {
                      return (
                        <IonItem
                          className="course"
                          lines={
                            i == (state.cart as any).length - 1
                              ? "full"
                              : "none"
                          }
                          style={{ fontWeight: 500, "--padding-bottom": (i == (state.cart as any).length - 1
                            ? "10px"
                            : "") }}
                        >
                          <IonLabel color="medium">
                            {e.category} {e.name}
                          </IonLabel>
                          <IonLabel color="medium">{e.price}</IonLabel>
                        </IonItem>
                      );
                    })
                  )}

                  <IonItem lines="none" style={{ fontWeight: 700 }}>
                    <IonLabel color="secondary">Razem</IonLabel>
                    <IonLabel style={{ textAlign: "right" }} color="secondary">
                      100,00 zł
                    </IonLabel>
                  </IonItem>
                </IonList>
                }

                

              </div>
              <div className="px-5 py-4 mt-2">
              <div className="row">
                <div className="col-12">
                  <IonItem lines="none">
                    <IonLabel className="checkbox-longtext" style={{ textOverflow: "clip", whiteSpace: "normal", textAlignt: "center" }} >
                      Zgadzam się z regulaminem Experientia
                    </IonLabel>
                    <IonCheckbox
                      slot="start"
                      checked={checked}
                      onIonChange={(e) =>
                        setChecked(e.detail.checked)
                      }
                    />
                  </IonItem>
                </div>
              </div>
                <Button routerLink="/OrderStatus" disabled={!Array.isArray(state.cart) || !state.cart.length} className="mt-4" color="primary" style={{ width: "100%" }} fontSize={20} height={60}>
                  <span>Przejdź do płatności</span>
                  <IonIcon style={{ marginLeft: "10px", marginTop: "-2px", width: "26px" }} src={chevronForwardOutline} />
                  </Button>
                  <Button fill="clear" color="primary" style={{ width: "100%" }} fontSize={17} height={45} label="Zobacz regulamin" />

              </div>
              
            </div>

            

          </div>
        </div>
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
