import {
  IonItem,
  IonContent,
  IonPage,
  IonRouterLink,
  IonRippleEffect,
  IonIcon,
  IonicSlides,
  NavContext,
  IonButtons,
  IonButton,
  IonBadge,
  IonSpinner,
  IonImg,
  IonPopover,
  IonList,
  IonLabel,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import "./Home.scss";

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import Footer from "../components/Footer";
import { useHistory } from "react-router";

import { ForwardProps } from "../components/Home/CategorySearch";

import CustomHeaderFade from "../components/CustomHeaderFade";

import { Pagination, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

import mainImage from "./../img/illustrations/illustration-5.svg";
import MobiSelect from "../components/Mobi/MobiSelect";
import { cartOutline, personCircle } from "ionicons/icons";
import HeaderToolbarMobile from "../components/Header/HeaderToolbarMobile";
import Button from "../components/Ebert/Button";
import MobiInput from "../components/Mobi/MobiInput";

import Chemistry from "../svg/chemia.svg";
import Biology from "../svg/biologia.svg";


import Lottie from 'react-lottie-player'
import CourseJson from '../lottie/course.json'
import Image from "../components/Ebert/Image";

const isInViewport = (element: Element) => {
  const rect = element.getBoundingClientRect();
  return (
      rect.top >= 0 &&
      // rect.left >= 0 &&
      rect.bottom - (rect.height/1.5) <= (window.innerHeight || document.documentElement.clientHeight)
      // && rect.right - (rect.width)  <= (window.innerWidth || document.documentElement.clientWidth)
  );
}



const Home: React.FC = () => {
  const ionContentRef = useRef<HTMLIonContentElement>(null);

  const cards = ["active", "", "", "", "", "", "", "", "", ""];

  const history = useHistory();

  const [choosenPrzedmiot, setChoosenPrzedmiot] = useState("Dowolny przedmiot");
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const ionPopoverRef = useRef<HTMLIonPopoverElement>(null);

  const [playAnimation, setPlayAnimation] = useState(true);

  useIonViewDidEnter(() => {
    setPlayAnimation(true);
  });
  useIonViewDidLeave(() => {
    setPlayAnimation(false);
  });

  const ContentOnScroll = (target: HTMLIonContentElement) => {
    const elements = target.querySelectorAll(
      ".activateOnScroll:not(.activate)"
    );

    elements.forEach((e) => {
      if (isInViewport(e)) {
        e.classList.add("active");
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if(ionContentRef.current)
      {
        ContentOnScroll(ionContentRef.current);
      }
    }, 200);
  }, [])

  return (
    <IonPage>
      <CustomHeaderFade
        page={"Strona główna"}
        title="Lista zleceń"
        logo
        extraButtons={<HeaderToolbarMobile />}
      />

      <IonContent
        id="home-page-controller"
        fullscreen={true}
        ref={ionContentRef}
        className={"background-lightgrey slide-transition-leave"}
        scrollEvents={true}
        onIonScroll={(e) => ContentOnScroll(e.target as HTMLIonContentElement)}
      >
        <section
          className="pb-6 mt-n13 pb-lg-12 bg-cover position-relative main-section"
          style={{
            background:
              "linear-gradient(90deg, #FFEBE5 0.13%, #EDEAF6 100.01%)",
            paddingTop: "255px",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-12 col-md-5 col-lg-5 order-md-2 "
              >
                {/* Image */}
                {/* <img
                        src="assets/img/illustrations/illustration-5.svg"
                        className="img-fluid ms-xl-n11 mw-md-174 mb-6 mb-md-0"
                        alt="..."
                      /> */}
                {/* <IonIcon className="main-image" src={mainImage} /> */}
                <Lottie
                  className="main-image activateOnScroll td-0"
                  speed={1}
                  loop
                  play={playAnimation}
                  animationData={CourseJson}
                />
              </div>
              <div className="col-12 col-md-7 col-lg-7 order-md-1">
                {/* Heading */}
                <h1 className="display-2 text-gradient-custom activateOnScroll td-1">
                  Gwarancja sukcesu na <span className="fw-bold">maturze</span>
                </h1>
                {/* Text */}
                <p className="lead pe-md-8 pe-xl-12 text-capitalize activateOnScroll td-2">
                  Technology is bringing a massive wave of evolution on learning
                  things in different ways.
                </p>
                {/* Buttons */}
                {/* <a
                  href="./course-list-v1.html"
                  className="btn btn-tangerine btn-wide rounded-lg mb-4 mb-md-0 me-md-5 text-w"
                  data-aosr="fade-up"
                  data-aosr-duration={200}
                >
                  GET STARTED
                </a> */}
                <Button
                  routerLink="/List"
                  height={60}
                  fontSize={19}
                  color="primary"
                  label="NASZE KURSY"
                  className="activateOnScroll td-3"
                />
                <Button
                  routerLink="/Corepetitions"
                  height={60}
                  fontSize={19}
                  color="secondary"
                  label="KOREPETYCJE"
                  className="mx-1 mx-lg-4 d-none d-md-inline activateOnScroll td-3"
                />
                {/* <a
                  href="./course-list-v1.html"
                  className="btn btn-gigas btn-wide rounded-lg d-none d-lg-inline-block"
                  data-aosr="fade-up"
                  data-aosr-duration={200}
                >
                  VIEW COURSES
                </a> */}
              </div>
            </div>{" "}
            {/* / .row */}
          </div>{" "}
          {/* / .container */}
          {/* Shape */}
          <div className="d-none d-wd-block shape shape-blur shape-left shape-fluid-y svg-shim text-white">
            <svg
              viewBox="0 0 310 800"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
            >
              <path
                fill="currentColor"
                d="M193,0H0v800h310c-64.6,0-117-52.4-117-117V0z"
              />
            </svg>
          </div>
        </section>

        <section className="pt-5 pb-9 py-md-11 p-3">
          <div className="container container-wd">
            <div className="row align-items-center mb-5">
              <div className="col-md mb-2 mb-md-0">
                <h1 className="mb-1 activateOnScroll td-0">Nasze kursy</h1>
                <p className="font-size-lg text-capitalize activateOnScroll td-0">
                  Discover your perfect program in our courses.
                </p>
              </div>

              <div
                style={{
                  cursor: "pointer",
                }}
                className="col-12 col-md-auto px-6 px-md-2 pb-0 pb-md-0"
                onClick={(e: any) => {
                  e.persist();
                  setShowPopover({ showPopover: true, event: e });
                }}
              >
                <div
                  className="activateOnScroll td-0"
                  id="przedmioty-trigger-home"
                  style={{
                    pointerEvents: "none",
                  }}
                >
                  <MobiInput
                    endIconSvg='<svg style="width: 30px; height: 30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path></svg>'
                    required
                    value={choosenPrzedmiot}
                  />
                </div>
                <IonPopover
                  ref={ionPopoverRef}
                  size="cover"
                  id="przedmioty-popover"
                  reference="trigger"
                  trigger="przedmioty-trigger-home"
                  side="bottom"
                  event={popoverState.event}
                  isOpen={popoverState.showPopover}
                  onDidDismiss={() =>
                    setShowPopover({ showPopover: false, event: undefined })
                  }
                  showBackdrop={false}
                >
                  <IonContent>
                    <IonList lines="full">
                      <IonItem
                        color={
                          choosenPrzedmiot == "Dowolny" ||
                          choosenPrzedmiot == "Dowolny przedmiot"
                            ? "primary"
                            : ""
                        }
                        button
                        onClick={() => {
                          setChoosenPrzedmiot("Dowolny przedmiot");
                          ionPopoverRef.current?.dismiss();
                        }}
                      >
                        <IonLabel
                          style={{
                            marginLeft: "8px",
                          }}
                        >
                          Dowolny przedmiot
                        </IonLabel>
                      </IonItem>
                      <IonItem
                        color={choosenPrzedmiot == "Chemia" ? "primary" : ""}
                        button
                        onClick={() => {
                          setChoosenPrzedmiot("Chemia");
                          ionPopoverRef.current?.dismiss();
                        }}
                      >
                        <IonLabel>Chemia</IonLabel>
                        <IonIcon slot="start" icon={Chemistry} />
                      </IonItem>
                      <IonItem
                        color={choosenPrzedmiot == "Biologia" ? "primary" : ""}
                        button
                        onClick={() => {
                          setChoosenPrzedmiot("Biologia");
                          ionPopoverRef.current?.dismiss();
                        }}
                      >
                        <IonLabel>Biologia</IonLabel>
                        <IonIcon
                          style={{
                            "--ion-color-contrast": "black",
                          }}
                          slot="start"
                          icon={Biology}
                        />
                      </IonItem>
                    </IonList>
                  </IonContent>
                </IonPopover>
              </div>
            </div>

            <div className="row align-items-center mb-9 d-none d-md-flex">
              <div className="col-lg-6 mb-6 mb-lg-0">
                <a
                  className="bg-cover rounded text-center py-10 py-md-14 d-block activateOnScroll td-0"
                  style={{
                    backgroundImage: "url(assets/img/covers/cover-2.jpg)",
                  }}
                  data-fancybox
                >
                  {/* Button */}
                  <div className="my-4 btn h-90p w-90p size-30-all rounded-circle btn-white d-inline-flex align-items-center justify-content-center shadow lift">
                    {/* Icon */}
                    <svg
                      width={14}
                      height={16}
                      viewBox="0 0 14 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.8704 6.15374L3.42038 0.328572C2.73669 -0.0923355 1.9101 -0.109836 1.20919 0.281759C0.508282 0.673291 0.0898438 1.38645 0.0898438 2.18929V13.7866C0.0898438 15.0005 1.06797 15.9934 2.27016 16C2.27344 16 2.27672 16 2.27994 16C2.65563 16 3.04713 15.8822 3.41279 15.6591C3.70694 15.4796 3.79991 15.0957 3.62044 14.8016C3.44098 14.5074 3.05697 14.4144 2.76291 14.5939C2.59188 14.6982 2.42485 14.7522 2.27688 14.7522C1.82328 14.7497 1.33763 14.3611 1.33763 13.7866V2.18933C1.33763 1.84492 1.51713 1.53907 1.81775 1.3711C2.11841 1.20314 2.47294 1.21064 2.76585 1.39098L12.2159 7.21615C12.4999 7.39102 12.6625 7.68262 12.6618 8.01618C12.6611 8.34971 12.4974 8.64065 12.2118 8.81493L5.37935 12.9983C5.08548 13.1783 4.9931 13.5623 5.17304 13.8562C5.35295 14.1501 5.73704 14.2424 6.03092 14.0625L12.8625 9.87962C13.5166 9.48059 13.9081 8.78496 13.9096 8.01868C13.9112 7.25249 13.5226 6.55524 12.8704 6.15374Z"
                        fill="currentColor"
                      />
                    </svg>
                  </div>
                </a>
              </div>
              <div className="col-lg-6">
                <div className="ms-xl-9 ps-xl-2">
                  <h3 className="font-size-xxl text-black mb-4 activateOnScroll td-0">
                    The Prodigious eLearning Courses for you
                  </h3>
                  <p className="mb-5 me-lg-6 line-height-lg activateOnScroll td-0">
                    Sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt. Labore et dolore magna aliqua. Ut enim ad minim
                    veniam, quis nostrud exercitation.
                  </p>
                  <ul className="mb-6 ps-4 activateOnScroll td-0">
                    <li className="mb-5">Creative Study Pattern</li>
                    <li className="mb-5">Quick Crash Courses</li>
                    <li className="mb-5">Certification Awarded</li>
                    <li className="mb-5">
                      Provided with Experimental Examples
                    </li>
                  </ul>
                  <IonRouterLink routerLink="/lesson" className="activateOnScroll td-0">
                    <a className="btn btn-sienna btn-wide rounded-lg lift text-white">
                      NEW COURSES
                    </a>
                  </IonRouterLink>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="activateOnScroll td-0" >
              <Swiper
                preloadImages={false}
                lazy={{
                  loadPrevNext: !(isPlatform("mobile") || isPlatform("tablet")),
                }}
                initialSlide={0}
                slideToClickedSlide={true}
                centeredSlides={true}
                modules={[Pagination, IonicSlides, Lazy]}
                pagination={{ clickable: true }}
                slidesPerView={1}
                breakpoints={{
                  576: {
                    slidesPerView: 2,
                    initialSlide: 0,
                    centeredSlides: false,
                  },
                  992: {
                    slidesPerView: 4,
                    initialSlide: 2,
                  },
                }}
                loop={false}
                onClick={(event) => {
                  event.slides.map((e) => {
                    e.querySelector(".card-container")?.classList.remove(
                      "activated-card"
                    );
                  });
                  event.clickedSlide
                    .querySelector(".card-container")
                    ?.classList.add("activated-card");

                  console.log(event.clickedSlide);
                }}
              >
                {cards.map((e, i) => {
                  return (
                    <SwiperSlide>
                      <div
                        className={
                          "col-12 card-container " +
                          (i == 2 ? "activated-card" : "")
                        }
                        style={{
                          overflow: "hidden",
                          position: "relative",
                          padding: "0 15px",
                        }}
                      >
                        <IonItem
                          onClick={() => {
                            if (isPlatform("mobile") || isPlatform("tablet")) {
                              history.push("lesson");
                            }
                          }}
                          lines="none"
                          style={{
                            "--inner-padding-start": "0",
                            "--inner-padding-end": "0",
                            "--padding-start": "0",
                          }}
                        >
                          {/* Card */}
                          <div className="card border shadow p-2">
                            {/* Image */}
                            <div className="card-zoom position-relative">
                              <div className="badge-float sk-fade-top top-0 right-0 mt-4 me-4">
                                <a className="btn btn-xs btn-dark text-white rounded-circle lift opacity-dot-7 me-1 p-2 d-inline-flex justify-content-center align-items-center w-36 h-36">
                                  {/* Icon */}
                                  <svg
                                    width={18}
                                    height={18}
                                    viewBox="0 0 18 18"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M17.8856 8.64995C17.7248 8.42998 13.8934 3.26379 8.99991 3.26379C4.10647 3.26379 0.274852 8.42998 0.114223 8.64974C-0.0380743 8.85843 -0.0380743 9.14147 0.114223 9.35016C0.274852 9.57013 4.10647 14.7363 8.99991 14.7363C13.8934 14.7363 17.7248 9.5701 17.8856 9.35034C18.0381 9.14169 18.0381 8.85843 17.8856 8.64995ZM8.99991 13.5495C5.39537 13.5495 2.27345 10.1206 1.3493 8.99965C2.27226 7.87771 5.38764 4.4506 8.99991 4.4506C12.6043 4.4506 15.726 7.8789 16.6505 9.00046C15.7276 10.1224 12.6122 13.5495 8.99991 13.5495Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M8.9999 5.43958C7.03671 5.43958 5.43945 7.03683 5.43945 9.00003C5.43945 10.9632 7.03671 12.5605 8.9999 12.5605C10.9631 12.5605 12.5603 10.9632 12.5603 9.00003C12.5603 7.03683 10.9631 5.43958 8.9999 5.43958ZM8.9999 11.3736C7.69103 11.3736 6.62629 10.3089 6.62629 9.00003C6.62629 7.6912 7.69107 6.62642 8.9999 6.62642C10.3087 6.62642 11.3735 7.6912 11.3735 9.00003C11.3735 10.3089 10.3088 11.3736 8.9999 11.3736Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </a>
                                <a className="btn btn-xs btn-dark text-white rounded-circle lift opacity-dot-7 p-2 d-inline-flex justify-content-center align-items-center w-36 h-36">
                                  {/* Icon */}
                                  <svg
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M11.2437 1.20728C10.0203 1.20728 8.87397 1.66486 7.99998 2.48357C7.12598 1.66486 5.97968 1.20728 4.7563 1.20728C2.13368 1.20728 0 3.341 0 5.96366C0 7.2555 0.425164 8.52729 1.26366 9.74361C1.91197 10.6841 2.80887 11.5931 3.92937 12.4454C5.809 13.8753 7.66475 14.6543 7.74285 14.6867L7.99806 14.7928L8.25384 14.6881C8.33199 14.6562 10.1889 13.8882 12.0696 12.4635C13.1907 11.6142 14.0881 10.7054 14.7367 9.7625C15.575 8.54385 16 7.26577 16 5.96371C16 3.341 13.8663 1.20728 11.2437 1.20728ZM8.00141 13.3353C6.74962 12.7555 1.33966 10.0142 1.33966 5.96366C1.33966 4.07969 2.87237 2.54698 4.75634 2.54698C5.827 2.54698 6.81558 3.03502 7.46862 3.88598L8.00002 4.57845L8.53142 3.88598C9.18446 3.03502 10.173 2.54698 11.2437 2.54698C13.1276 2.54698 14.6604 4.07969 14.6604 5.96366C14.6603 10.0433 9.25265 12.7613 8.00141 13.3353Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </a>
                              </div>
                              <a className="card-img sk-thumbnail d-block">
                                <img
                                  className="rounded shadow-light-lg swiper-lazy"
                                  data-src="assets/img/products/product-1.jpg"
                                  // src="assets/img/products/product-1.jpg"
                                  alt="..."
                                />
                                <IonSpinner
                                  className="swiper-lazy-preloader"
                                  name="crescent"
                                  style={{
                                    left: "calc( 50% - 14px )",
                                    color: "var(--ion-color-primary)",
                                    top: "calc( 50% - 14px )",
                                    transform: "scale(2)",
                                    position: "absolute",
                                  }}
                                />
                              </a>
                              <span className="badge sk-fade-bottom badge-lg badge-orange badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                                <span className="text-white text-uppercase fw-bold font-size-xs">
                                  BEST SELLER
                                </span>
                              </span>
                            </div>
                            {/* Footer */}
                            <div className="card-footer px-2 pb-2 mb-1 pt-4 position-relative">
                              <a className="d-block">
                                <div className="avatar avatar-xl badge-float position-absolute top-0 right-0 mt-n6 me-5 rounded-circle shadow border border-white border-w-lg">
                                  <img
                                    src="assets/img/avatars/avatar-1.jpg"
                                    alt="..."
                                    className="avatar-img rounded-circle"
                                  />
                                </div>
                              </a>
                              {/* Preheading */}
                              <a>
                                <span
                                  className="mb-1 d-inline-block text-gray-800 text-start"
                                  style={{ width: "100%" }}
                                >
                                  Photography
                                </span>
                              </a>
                              {/* Heading */}
                              <div className="position-relative">
                                <a className="d-block stretched-link">
                                  <h4 className="h-md-48 h-lg-58 me-md-6 me-lg-10 me-xl-4 mb-2 text-start">
                                    Fashion Photography From Professional
                                  </h4>
                                </a>
                                <div className="d-lg-flex align-items-end flex-wrap mb-n1">
                                  <div className="star-rating mb-2 mb-lg-0 me-lg-3">
                                    <div
                                      className="rating"
                                      style={{ width: "50%" }}
                                    />
                                  </div>
                                  <div className="font-size-sm">
                                    <span>5.45 (5.8k+ reviews)</span>
                                  </div>
                                </div>
                                <div className="row mx-n2 align-items-end mh-50">
                                  <div className="col px-2">
                                    <ul className="nav mx-n3">
                                      <li className="nav-item px-3">
                                        <div className="d-flex align-items-center">
                                          <div className="me-2 d-flex icon-uxs text-secondary">
                                            {/* Icon */}
                                            <svg
                                              width={20}
                                              height={20}
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M17.1947 7.06802L14.6315 7.9985C14.2476 7.31186 13.712 6.71921 13.0544 6.25992C12.8525 6.11877 12.6421 5.99365 12.4252 5.88303C13.0586 5.25955 13.452 4.39255 13.452 3.43521C13.452 1.54098 11.9124 -1.90735e-06 10.0197 -1.90735e-06C8.12714 -1.90735e-06 6.58738 1.54098 6.58738 3.43521C6.58738 4.39255 6.98075 5.25955 7.61414 5.88303C7.39731 5.99365 7.1869 6.11877 6.98502 6.25992C6.32752 6.71921 5.79178 7.31186 5.40787 7.9985L2.8447 7.06802C2.33612 6.88339 1.79688 7.26044 1.79688 7.80243V16.5178C1.79688 16.8465 2.00256 17.14 2.31155 17.2522L9.75312 19.9536C9.93073 20.018 10.1227 20.0128 10.2863 19.9536L17.7278 17.2522C18.0368 17.14 18.2425 16.8465 18.2425 16.5178V7.80243C18.2425 7.26135 17.704 6.88309 17.1947 7.06802ZM10.0197 1.5625C11.0507 1.5625 11.8895 2.40265 11.8895 3.43521C11.8895 4.46777 11.0507 5.30792 10.0197 5.30792C8.98866 5.30792 8.14988 4.46777 8.14988 3.43521C8.14988 2.40265 8.98866 1.5625 10.0197 1.5625ZM9.23844 18.1044L3.35938 15.9703V8.91724L9.23844 11.0513V18.1044ZM10.0197 9.67255L6.90644 8.54248C7.58164 7.51892 8.75184 6.87042 10.0197 6.87042C11.2875 6.87042 12.4577 7.51892 13.1329 8.54248L10.0197 9.67255ZM16.68 15.9703L10.8009 18.1044V11.0513L16.68 8.91724V15.9703Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </div>
                                          <div className="font-size-sm">
                                            5 lessons
                                          </div>
                                        </div>
                                      </li>
                                      <li className="nav-item px-3">
                                        <div className="d-flex align-items-center">
                                          <div className="me-2 d-flex icon-uxs text-secondary">
                                            {/* Icon */}
                                            <svg
                                              width={16}
                                              height={16}
                                              viewBox="0 0 16 16"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M14.3164 4.20996C13.985 4.37028 13.8464 4.76904 14.0067 5.10026C14.4447 6.00505 14.6667 6.98031 14.6667 8C14.6667 11.6759 11.6759 14.6667 8 14.6667C4.32406 14.6667 1.33333 11.6759 1.33333 8C1.33333 4.32406 4.32406 1.33333 8 1.33333C9.52328 1.33333 10.9543 1.83073 12.1387 2.77165C12.4259 3.00098 12.846 2.95296 13.0754 2.66471C13.3047 2.37663 13.2567 1.95703 12.9683 1.72803C11.5661 0.613607 9.8016 0 8 0C3.58903 0 0 3.58903 0 8C0 12.411 3.58903 16 8 16C12.411 16 16 12.411 16 8C16 6.77767 15.7331 5.60628 15.2067 4.51969C15.0467 4.18766 14.6466 4.04932 14.3164 4.20996Z"
                                                fill="currentColor"
                                              />
                                              <path
                                                d="M7.99967 2.66663C7.63167 2.66663 7.33301 2.96529 7.33301 3.33329V7.99996C7.33301 8.36796 7.63167 8.66663 7.99967 8.66663H11.333C11.701 8.66663 11.9997 8.36796 11.9997 7.99996C11.9997 7.63196 11.701 7.33329 11.333 7.33329H8.66634V3.33329C8.66634 2.96529 8.36768 2.66663 7.99967 2.66663Z"
                                                fill="currentColor"
                                              />
                                            </svg>
                                          </div>
                                          <div className="font-size-sm">
                                            8h 12m
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="col-auto px-2 text-right">
                                    <del className="font-size-sm">$959</del>
                                    <ins className="h4 mb-0 d-block mb-lg-n1">
                                      $415.99
                                    </ins>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </IonItem>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              </div>
            </div>
          </div>
        </section>

        <section className="p-3 py-5 py-md-8 position-relative">
          <div className="container container-wd">
            <div className="course-description">
              <div className="row justify-content-between align-items-center mb-6">
                <div className="col-md-5 order-2 activateOnScroll td-0">
                  <h2>Learn at your own pace</h2>
                  <p className="line-height-md">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis parturient montes.Join
                    millions of people from around the world learning together.
                    Online learning is as easy and natural as chatting.
                  </p>
                </div>
                <div className="col-md-6 order-1 mb-8 mb-md-0 activateOnScroll td-0">
                  <Image 
                    width={585}
                    height={537}
                    className="img-fluid"
                    src="assets/img/post/post-10.png"
                    alt="..."
                  />
                  {/* <Blurhash
                    className="img-fluid"
                    hash="[lMtaLo2xuR+xuxuMxae?^ozt6s:R-R*t8RjyEWBM{floeRjoztRt7j@ayWBaeV@t7ofaeWBjGju"
                    width={"100%"}
                    height={"auto"}
                    resolutionX={585}
                    resolutionY={537}
                    punch={1}
                  /> */}
                </div>
              </div>
              <div className="row justify-content-between align-items-center">
                <div
                  className="col-md-5 order-2 order-md-1 activateOnScroll td-0"
                >
                  <h2>Community of opportunities</h2>
                  <p className="line-height-md">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis parturient montes.Join
                    millions of people from around the world learning together.
                    Online learning is as easy and natural as chatting.
                  </p>
                </div>
                <div
                  className="col-md-6 order-1 mb-8 mb-md-0 order-md-2 text-right activateOnScroll td-0"
                >
                  <Image 
                    width={593}
                    height={544}
                    className="img-fluid"
                    src="assets/img/post/post-11.png"
                    alt="..."
                  />
                </div>
              </div>
              <div className="row justify-content-between align-items-center mb-6">
                <div className="col-md-5 order-2 activateOnScroll td-0">
                  <h2>Learn at your own pace</h2>
                  <p className="line-height-md">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis parturient montes.Join
                    millions of people from around the world learning together.
                    Online learning is as easy and natural as chatting.
                  </p>
                </div>
                <div
                  className="col-md-6 order-1 mb-8 mb-md-0 activateOnScroll td-0"
                >
                  <Image 
                    width={585}
                    height={537}
                    className="img-fluid"
                    src="assets/img/post/post-10.png"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="d-none d-wd-block shape shape-blur shape-left shape-flip-y-rotate shape-fluid-y svg-shim text-white">
            <svg
              viewBox="0 0 310 800"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
            >
              <path
                fill="currentColor"
                d="M193,0H0v800h310c-64.6,0-117-52.4-117-117V0z"
              />
            </svg>
          </div> */}
        </section>

        <section className="pt-5 pb-2 pb-md-9 py-md-11 px-4 px-md-0">
          <div className="container">
            <div className="text-center mb-4 mb-md-7">
              <h1 className="mb-1 px-2 activateOnScroll td-0">Co mówią o nas nasi kursanci?</h1>
              {/* <p className="font-size-lg mb-0">
                Discover your perfect program in our courses.
              </p> */}
            </div>

            <div className="row activateOnScroll td-1">
              <Swiper
                initialSlide={0}
                centeredSlides={false}
                slidesPerView={1}
                breakpoints={{
                  576: {
                    slidesPerView: 2,
                    initialSlide: 0,
                  },
                  992: {
                    slidesPerView: 3,
                    initialSlide: 0,
                  },
                }}
                loop={true}
                modules={[Pagination, IonicSlides]}
                pagination={{
                  clickable: true,
                  totalClass: "d-none d-md-block",
                }}
              >
                {["", "", "", "", ""].map((e, i) => {
                  return (
                    <SwiperSlide>
                      <div
                        className="col-12"
                        style={{
                          paddingRight: 15,
                          paddingLeft: 15,
                          textAlign: "left",
                        }}
                      >
                        {/* Card */}
                        <div className="card border shadow p-6 lift-md">
                          {/* Image */}
                          <div className="card-zoom">
                            <div className="d-flex align-items-center">
                              {/* <div className="avatar avatar-custom me-5">
          <img src="assets/img/avatars/avatar-2.jpg" alt="..." className="avatar-img rounded-circle" />
        </div> */}
                              <div className="media-body">
                                <h4
                                  className="mb-0"
                                  style={{
                                    fontWeight: "700",
                                    display: "block",
                                  }}
                                >
                                  Robert K.
                                </h4>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "grey",
                                    marginTop: "-2px",
                                    display: "block",
                                  }}
                                >
                                  Matematyka - cały kurs
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* Footer */}
                          <div className="card-footer px-0 pb-0">
                            <p
                              className="mb-0 text-capitalize"
                              style={{
                                fontSize: "16px",
                                color: "var(--ion-color-dark)",
                              }}
                            >
                              “ I believe in lifelong learning and Skola is a
                              great place to learn from experts. I've learned a
                              lot and recommend it to all my friends “
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </section>

        <section className="py-6 pb-8 pb-md-13 py-md-11 jarallax">
          <div className="container text-center py-xl-4">
            <h1 className="text-capitalize activateOnScroll td-0">
              Get personal learning recommendations
            </h1>
            <div className="font-size-lg mb-md-6 mb-4 activateOnScroll td-1">
              Enhance your skills with best Online courses
            </div>
            <div className="mx-auto activateOnScroll td-2">
              <a
                href="./course-list-v1.html"
                className="btn btn-primary btn-x-wide lift d-inline-block"
              >
                GET STARTED NOW
              </a>
            </div>
          </div>
          <div
            id="jarallax-container-0"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: -100,
            }}
          >
            <div
              style={{
                backgroundPosition: "50% 50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage:
                  'url("file:///C:/Zlecenia/exper/themeforest-2A3eymQR-skola-lms-online-courses-html-template/demo/assets/img/illustrations/illustration-1.jpg")',
                position: "absolute",
                top: 0,
                left: 0,
                width: 2543,
                height: "589.712px",
                overflow: "hidden",
                pointerEvents: "none",
                marginTop: "369.644px",
                transform: "translate3d(0px, -436.468px, 0px)",
              }}
            />
          </div>
        </section>

        <Footer/>
      </IonContent>
    </IonPage>
  );
};

export default Home;
