import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonSegment,
  IonSegmentButton,
  IonRadioGroup,
  IonRadio,
  IonAccordionGroup,
  IonList,
  IonAccordion,
  IonButtons,
  IonIcon,
  IonButton,
  IonBadge,
  useIonViewDidLeave,
} from "@ionic/react";

import {
  Datepicker,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
} from "react";

import Button from "../../components/Ebert/Button";

import { isPlatform, ScrollDetail } from "@ionic/core";

import MobiInput from "../../components/Mobi/MobiInput";
import "./RecoverySuccess.scss";

import SuccessJson from "../../lottie/success.json";
import Lottie from 'react-lottie-player'

import Footer from "../../components/Footer";

import CustomHeaderFade from "../../components/CustomHeaderFade";

import {
  cartOutline,
  contractOutline,
  layersOutline,
  personCircle,
} from "ionicons/icons";
import MobiSelect from "../../components/Mobi/MobiSelect";
import HeaderToolbarMobile from "../../components/Header/HeaderToolbarMobile";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const Home: React.FC = () => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  const [segmentValue, setSegmentValue] = useState("login");

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);


  const segmentContainerRef = useRef<HTMLDivElement>(null);
  const ionSegmentRef = useRef<HTMLIonSegmentElement>(null);
  const ionSegmentHeaderRef = useRef<HTMLIonSegmentElement>(null);
  const ionHeaderRef = useRef<HTMLIonHeaderElement>(null);

  const [isVisiting, setIsVisiting] = useState(false); 

  const [playAnimation, setPlayAnimation] = useState(true);
  useIonViewDidEnter(() => {
    setPlayAnimation(true);
  });
  useIonViewDidLeave(() => {
    setPlayAnimation(false);
  });

  useIonViewDidLeave(() => {
    setIsVisiting(false);
  })

  useIonViewWillEnter(() => {

    setIsVisiting(true);

    segmentContainerRef.current?.querySelectorAll(".segment-single").forEach((_e) => {
      const parsed = _e as HTMLDivElement;
      parsed.style.display = "";
    });

    const e = segmentContainerRef.current?.querySelector(".segment-single" + "." + segmentValue) as HTMLDivElement;

    console.log(e)

    if(e)
    {
      e.style.display = "block";
    }

    

  })

  return (
    <IonPage>
      <CustomHeaderFade
        ionHeaderRef={ionHeaderRef}
        title="Lista zleceń"
        logo
        extraButtons={
          <HeaderToolbarMobile />
        }
      />

      <IonContent
        id="login-recovery-page-controller"
        className="background-lightgrey slide-transition"
      >
        <div
          className="container pb-4 pb-xl-7 d-lg-flex align-items-center container-mheight"
          style={{
          }}
        >

          <div className="row justify-content-center w-100pr " ref={segmentContainerRef}>
            <div className="col-12 col-lg-8 p-6 segment-single login my-md-10 my-lg-0">

            <div className="row justify-content-center">
                {
                  isVisiting
                  ?
                  <Lottie
                        speed={1}
                        play={playAnimation}
                        animationData={SuccessJson}
                        style={{ height: "200px", width: "200px", marginBottom: "30px" }}
                        loop={false}
                      />
                      :
                      <></>
                }
              </div>

              <div className="row text-center">
                <h2>Prośba o odzyskanie hasła została przyjęta!</h2>
              </div>

              <div className="row text-center">
                <h4>
                  Wiadomość umożliwiająca zresetowanie hasła została wysłana na twój adres <strong>email</strong>.
                  Link resetujący hasło jest aktywny przez następne 24 godziny.
                </h4>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
