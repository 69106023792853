import { createAnimation } from "@ionic/react";

const easings = [
  "cubic-bezier(0.190, 1.000, 0.220, 1.000)", // szybkie przejscie jak karta produktów z boku
  "cubic-bezier(0.190, 1.000, 0.190, 1.000)" // szybkie przejscie jak karta produktów z boku - taki sam 
]

export const noAnimation = (baseEl: any, opts: any) => {
  return createAnimation();
}

export const mobileAnimation = (baseEl: any, opts: any) => {

  const enteringEl = opts.enteringEl as HTMLDivElement | undefined;
  const leavingEl = opts.leavingEl as HTMLDivElement | undefined;


  console.log("1 " + enteringEl?.classList)
  console.log("2 " + leavingEl?.classList)

  if(opts.direction == "forward")
  {
    if (
      (enteringEl?.querySelector("ion-content.slide-transition-enter") && leavingEl?.querySelector("ion-content.slide-transition-leave"))
    ) {
      return translateAnimationANIMATEDSCALE(baseEl, opts);
    }
  }
  else
  {
    if (
      (enteringEl?.querySelector("ion-content.slide-transition-leave") && leavingEl?.querySelector("ion-content.slide-transition-enter"))
    ) {
      return translateAnimationANIMATEDSCALE(baseEl, opts);
    }
  }

  // return opacityAnimation(baseEl, opts);
  return defaultMobileAnimation(baseEl, opts);

};

const defaultMobileAnimation = (baseEl: any, opts: any) => {

  if(opts.direction == "forward")
  {
    const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("transform", "translate3d(0, 40px, 0)", "translate3d(0, 0, 0)")
    .fromTo("opacity", 0, 1)
    .easing(easings[1])
    .duration(200);

    return createAnimation().addAnimation(enteringAnimation);
  }
  else
  {
    const leavingElement = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo("transform", "translate3d(0, 0, 0)", "translate3d(0, 40px, 0)")
    .fromTo("opacity", 1, 0)
    .easing(easings[1])
    .duration(200);

    const enteringElement = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("opacity", 0, 1)
    .easing(easings[1])
    .duration(200);

    return createAnimation().addAnimation(leavingElement).addAnimation(enteringElement);
  }

};

export const translateAnimation = (baseEl: any, opts: any) => {

  if(opts.direction == "forward")
  {
    const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("transform", "translate3d(100%, 0, 0)", "translate3d(0, 0, 0)")
    .fromTo("opacity", 1, 1)
    .easing(easings[0])
    .duration(300);

    const leavingAnimation = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo("transform", "translate3d(0, 0, 0)", "translate3d(150px, 0, 0)")
    .fromTo("opacity", 1, 1)
    .easing(easings[0])
    .duration(300);

    return createAnimation().addAnimation(enteringAnimation).addAnimation(leavingAnimation);
  }
  else
  {
    const leavingElement = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo("transform", "translate3d(0, 0, 0)", "translate3d(100%, 0, 0)")
    .easing(easings[0])
    .duration(300);

    const enteringElement = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("opacity", 0, 1)
    .easing(easings[0])
    .duration(1);

    return createAnimation().addAnimation(leavingElement).addAnimation(enteringElement);
  }
};

export const translateAnimationANIMATEDSCALE = (baseEl: any, opts: any) => {

  if(opts.direction == "forward")
  {
    const enteringAnimation = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("transform", "translate3d(100%, 0, 0)", "translate3d(0, 0, 0)")
    .fromTo("opacity", 1, 1)
    .easing(easings[0])
    .duration(400);

    const leavingElement = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo("transform", "translateX(0)", "translateX(-150px)")
    .easing(easings[1])
    .duration(400);

    return createAnimation().addAnimation(enteringAnimation).addAnimation(leavingElement);
  }
  else
  {
    const leavingElement = createAnimation()
    .addElement(opts.leavingEl)
    .fromTo("transform", "translate3d(0, 0, 0)", "translate3d(100%, 0, 0)")
    .easing(easings[0])
    .duration(300);

    const enteringElement = createAnimation()
    .addElement(opts.enteringEl)
    .fromTo("transform", "translateX(-150px)", "translateX(0)")
    .fromTo("opacity", "1", "1")
    .easing(easings[1])
    .duration(300);

    return createAnimation().addAnimation(leavingElement).addAnimation(enteringElement);
  }
};

export const opacityAnimation = (baseEl: any, opts: any) => {
    const enteringAnimation = createAnimation()
      .addElement(opts.enteringEl)
      .fromTo('opacity', 0, 1)
      .easing("ease-in-out")
      .duration(100);
    
    const leavingAnimation = createAnimation()
      .addElement(opts.leavingEl)
      .fromTo('opacity', 1, 0)
      .easing("ease-in-out")
      .duration(100);
    
    const animation = createAnimation()
      .addAnimation(enteringAnimation)
      .addAnimation(leavingAnimation);
    
    return animation;
  };