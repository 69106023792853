import { AnimationBuilder, IonButton, IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { AnyKindOfDictionary } from "lodash";
import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  forwardRef,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ButtonLoader, OnKeyDownHours, Spinner } from "../Common";

import './Button.css';

type Props = {
  children?: ReactNode;
  label?: string;
  placeholder?: string;
  type?: string;
  autoComplete?: string;
  id?: string;
  responsive?: {[key: string]: any};
  startIcon?: string;
  startIconStyle?: React.CSSProperties;
  startIconSvg?: string;
  loading?: boolean;
  onKeyDown?: (event: KeyboardEvent) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
  onClick?: MouseEventHandler<HTMLIonButtonElement> | undefined;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  maxLength?: string;
  step?: string;
  value?: any;
  responsiveDefault?: boolean;
  disabled?: boolean;
  height?: number;
  fontSize?: number;
  focus?: boolean;
  unFocus?: boolean;
  color?: string | undefined;
  dim?: boolean;
  fontWeight?: "light" | "bold";
  style?: React.CSSProperties;
  borderRadius?: number;
  routerLink?: string;
  routerAnimation?: AnimationBuilder;
  fill?: "clear" | "outline" | "solid" | "default" | undefined;
  strong?: boolean;
  slot?: string;
}

// const _responsive = {
//   xsmall: {
//     className: "input-primary",
//   },
//   medium: {
//     className: "input-bold",
//   },
// }


const Button: React.FC<Props> = ({
  children,
  placeholder,
  label,
  type,
  autoComplete,
  id,
  responsive,
  startIcon,
  startIconStyle,
  startIconSvg,
  loading,
  onKeyDown,
  onChange,
  onKeyPress,
  onKeyUp,
  onClick,
  error,
  errorMessage,
  className,
  maxLength,
  step,
  value,
  disabled,
  height,
  fontSize,
  focus,
  unFocus,
  style,
  borderRadius,
  routerLink,
  routerAnimation,
  fill,
  strong,
  slot,
  color = undefined,
  responsiveDefault = true,
  dim = false,
  fontWeight = "light"
}) => {
  return (
      <IonButton
        id={id}
        slot={slot}
        strong={strong}
        fill={fill}
        routerAnimation={routerAnimation}
        routerLink={routerLink}
        mode="md"
        type="button"
        className={
          (loading ? "btn-loading" : "") +
          (className ? " " + className : "")
        }
        style={{
          ...style,
          height: height ? height + "px" : "",
          fontSize: fontSize ? fontSize + "px" : "",
          opacity: unFocus ? "0.5" : "",
          "--border-radius": borderRadius ? borderRadius + "px" : "",
        }}
        disabled={loading || disabled}
        onClick={onClick}
        color={color}
      >
        {startIcon ? <i className={startIcon} style={startIconStyle} /> : <></>}

        <ButtonLoader />
        {label ? <span>{label}</span> : <></>}
        {children}
      </IonButton>
  );
};

export default Button;
