import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonSegment,
  IonSegmentButton,
  IonRadioGroup,
  IonRadio,
  IonAccordionGroup,
  IonList,
  IonAccordion,
  IonButtons,
  IonIcon,
  IonButton,
  IonBadge,
  IonSplitPane,
  IonMenu,
  IonRouterLink,
  IonRippleEffect,
  IonRouterOutlet,
  NavContext,
  useIonViewDidLeave,
} from "@ionic/react";

import {
  Datepicker,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  ReactNode,
  useContext,
} from "react";

import Button from "./../components/Ebert/Button";

import Header from "../components/JobsterHeader";
import ContentBox from "../components/ContentBox";

import { useDebounce, useDebouncedCallback } from "use-debounce";

import { Main } from "../components/HomeScript";
import { isPlatform, ScrollDetail } from "@ionic/core";

import MobiInput from "../components/Mobi/MobiInput";
import { LocationModelAttributes } from "../components/Order/Location";
import { isValidPostCode, onScrollHeader } from "../components/Common";
import MobiPopup from "../components/Mobi/MobiPopup";

import "./Profile.scss";

import JobPost from "../components/Home/JobPost";

import Footer from "../components/Footer";
import {
  Route,
  RouteComponentProps,
  useHistory,
  useParams,
} from "react-router";
import MobiButton from "../components/Mobi/MobiButton";

import { Link } from "react-router-dom";
import CustomHeaderFade from "../components/CustomHeaderFade";
import CustomHeaderCondense from "../components/CustomHeaderCondense";
import MobiTextarea from "../components/Mobi/MobiTextarea";
import {
  calendar,
  cartOutline,
  contractOutline,
  desktop,
  home,
  layersOutline,
  people,
  personCircle,
  document,
  desktopOutline,
  calendarOutline,
  documentOutline,
  peopleOutline,
} from "ionicons/icons";
import MobiSelect from "../components/Mobi/MobiSelect";
import HeaderToolbarMobile from "../components/Header/HeaderToolbarMobile";
import Courses from "./Profile/Courses";
import Notes from "./Profile/Notes";
import Corepetitions from "./Profile/Corepetitions";
import { noAnimation } from "../components/Ebert/Animations";

type UserProps = {
  firstname: string;
  lastname: string;
};

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const Home: React.FC<RouteComponentProps> = ({ match, history }) => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  const { navigate } = useContext(NavContext);

  type NotesProps = {
    id: number;
    note: string;
    buttonTime: string;
    buttonText: string;
    date: string;
  };

  const [notes, setNotes] = useState<NotesProps[]>([]);

  useEffect(() => {
    if (notes.length <= 0) {
      setNotes([
        {
          id: 1,
          note: "Notatka 1",
          buttonTime: "1:26:23",
          buttonText: "Matematyka - Podstawy geometrii",
          date: "11:28 14-02-2022",
        },
      ]);
    }
  }, []);

  useEffect(() => {
    console.log((match.params as any).section);
  }, [match.params]);

  // useEffect(() => {

  //   const params = match.params;
  //   const section = (params as any).section;
  //   setSegmentValue(section);

  //   console.log("sekcja: " + section)

  // }, [match.params])

  //const {section} = useParams<UseParamsProps>();

  const [userData, setUserData] = useState();

  const [segmentValue, setSegmentValue] = useState<string>(
    history.location.pathname.replace("/profile/", "")
  );

  const menu: MenuProps[] = [
    {
      id: "data",
      title: "Dane kursanta",
      icon: peopleOutline,
      content: (
        <>
          <div className="col-12 col-lg-8 px-6 px-lg-10 pt-6">
            <h3>Dane podstawowe</h3>
            <div className="row mt-2">
              <div className="col-12 col-md-6">
                <MobiInput label="Imię" required />
              </div>
              <div className="col-12 col-md-6 mt-4 mt-md-0">
                <MobiInput label="Nazwisko" required />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <MobiInput label="Adres email" required />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <MobiInput label="Numer telefonu" required />
              </div>
            </div>
            <h3 className="mt-8">Adres do płatności</h3>
            <div className="row mt-2">
              <div className="col-12 col-md-6">
                <MobiInput label="Miasto" required />
              </div>
              <div className="col-12 col-md-6 mt-4 mt-md-0">
                <MobiInput label="Kod pocztowy" required />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12 col-md-7">
                <MobiInput
                  label="Adres"
                  placeholder="Nazwa ulicy i numer domu"
                  required
                />
              </div>
              <div className="col-12 col-md-5 mt-4 mt-md-0">
                <MobiInput label="Numer mieszkania" required />
              </div>
            </div>
            <div className="row justify-content-end mt-8">
              <div className="col-auto">
                <Button
                  color="secondary"
                  height={60}
                  fontSize={20}
                  label="Zapisz zmiany"
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      id: "courses",
      title: "Twoje kursy",
      icon: desktopOutline,
      content: <Courses />,
    },
    {
      id: "corepetition",
      title: "Twoje korepetycje",
      icon: calendarOutline,
      content: <Corepetitions />,
    },
    {
      id: "notes",
      title: "Notatki",
      icon: documentOutline,
      content: <Notes />,
    },
  ];

  type MenuProps = {
    id: string;
    title: string;
    icon: string;
    content: ReactNode;
  };

  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);

  const segmentContainerRef = useRef<HTMLDivElement>(null);
  const ionSegmentRef = useRef<HTMLIonSegmentElement>(null);
  const ionSegmentHeaderRef = useRef<HTMLIonSegmentElement>(null);
  const ionHeaderRef = useRef<HTMLIonHeaderElement>(null);

  return (
    <>
      <CustomHeaderFade
        ionHeaderRef={ionHeaderRef}
        title="Twój profil"
        extraButtons={<HeaderToolbarMobile />}
        segment={
          isPlatform("mobile") ? (
            <IonToolbar>
              <IonSegment
                scrollable
                ref={ionSegmentHeaderRef}
                value={segmentValue}
                className="px-3"
                mode="md"
                onScroll={(event) => {
                  const target = event.target as HTMLIonSegmentElement;

                  if (target) {
                    ionSegmentRef.current?.scrollTo(target.scrollLeft, 0);
                  }

                  console.log(target.scrollLeft);
                }}
                onIonChange={(event) => {
                  setSegmentValue(
                    event.detail.value ? event.detail.value : "data"
                  );
                  navigate(
                    event.detail.value ? event.detail.value : "data",
                    "none",
                    "replace"
                  );

                  // if (segmentContainerRef.current) {
                  //   segmentContainerRef.current
                  //     .querySelectorAll(".segment-single")
                  //     .forEach((_e) => {
                  //       const parsed = _e as HTMLDivElement;
                  //       parsed.style.display = "";
                  //     });

                  //   const e = segmentContainerRef.current.querySelector(
                  //     ".segment-single" + "." + event.detail.value
                  //   ) as HTMLDivElement;

                  //   if (e) {
                  //     e.style.display = "block";
                  //   }

                  //   segmentContainerRef.current
                  //     ?.querySelector("#under-segment")
                  //     ?.scrollIntoView({ block: "start" });
                  // }
                }}
              >
                <IonSegmentButton value="data">
                  <IonLabel>Dane kursanta</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="courses">
                  <IonLabel>Kursy</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="corepetition">
                  <IonLabel>Korepetycje</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="notes">
                  <IonLabel>Notatki</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonToolbar>
          ) : (
            <></>
          )
        }
      />
      <IonContent
        id="profile2-page-controller"
        className="background-lightgrey"
        style={{ "--ion-background-color": "white" }}
      >
          <div className="container shadow-basic mt-0 mt-lg-7">
            <div className="row" style={{ height: "100%" }} >

              <div className="col-4 d-none d-lg-block">
              <IonList
                lines="full"
                style={{
                  padding: "40px 30px",
                }}
              >
                {menu.map((e) => {
                  return (
                    <IonItem
                      // routerLink={"/profile/" + e.id}
                      onClick={() => {
                        setSegmentValue(e.id);
                        navigate("/profile/" + e.id, "root", "replace");
                      }}
                      button
                      style={{
                        position: "relative",
                        "--min-height": "59px",
                      }}
                      color={
                        history.location.pathname.replace("/profile/", "") ==
                        e.id
                          ? "primary"
                          : ""
                      }
                    >
                      <IonLabel
                        style={{
                          fontWeight: "600",
                        }}
                        color={
                          history.location.pathname.replace("/profile/", "") ==
                          e.id
                            ? undefined
                            : "secondary"
                        }
                      >
                        {e.title}
                      </IonLabel>
                      <IonIcon
                        color={
                          history.location.pathname.replace("/profile/", "") ==
                          e.id
                            ? undefined
                            : "secondary"
                        }
                        slot="start"
                        icon={e.icon}
                      />
                    </IonItem>
                  );
                })}
              </IonList>
              </div>

              <div className="col-12 col-md-8 position-relative"  >

{/*-- the main content --*/}
<IonPage id="profile">
              
                <IonRouterOutlet animated={true} mode={"md"} >
                <IonContent
                id="profile-page-controller"
                className="background-lightgrey"
                style={{ "--ion-background-color": "white" }}
              >
                  <Route
                    exact
                    path={`${match.url}/data`}
                    component={() => (
                      <>
                        <div className="px-6 px-lg-10 pt-6 pb-5">
                          <h3>Dane podstawowe</h3>
                          <div className="row mt-2">
                            <div className="col-12 col-md-6">
                              <MobiInput label="Imię" required />
                            </div>
                            <div className="col-12 col-md-6 mt-4 mt-md-0">
                              <MobiInput label="Nazwisko" required />
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-12">
                              <MobiInput label="Adres email" required />
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-12">
                              <MobiInput label="Numer telefonu" required />
                            </div>
                          </div>
                          <h3 className="mt-8">Adres do płatności</h3>
                          <div className="row mt-2">
                            <div className="col-12 col-md-6">
                              <MobiInput label="Miasto" required />
                            </div>
                            <div className="col-12 col-md-6 mt-4 mt-md-0">
                              <MobiInput label="Kod pocztowy" required />
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-12 col-md-7">
                              <MobiInput
                                label="Adres"
                                placeholder="Nazwa ulicy i numer domu"
                                required
                              />
                            </div>
                            <div className="col-12 col-md-5 mt-4 mt-md-0">
                              <MobiInput label="Numer mieszkania" required />
                            </div>
                          </div>
                          <div className="row justify-content-end mt-8">
                            <div className="col-auto">
                              <Button
                                color="secondary"
                                height={60}
                                fontSize={20}
                                label="Zapisz zmiany"
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  />
                  <Route
                    exact
                    path={`${match.url}/courses`}
                    component={() => <Courses />}
                  />
                  <Route
                    exact
                    path={`${match.url}/corepetition`}
                    component={() => <Corepetitions />}
                  />
                  <Route
                    exact
                    path={`${match.url}/notes`}
                    component={() => <Notes />}
                  />
                  </IonContent>
                </IonRouterOutlet>
              
            </IonPage>

              </div>

            </div>

            
          </div>

        <Footer />

      </IonContent>
    </>
  );
};

export default Home;
