import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonSegment,
  IonSegmentButton,
  IonRadioGroup,
  IonRadio,
  IonAccordionGroup,
  IonList,
  IonAccordion,
  IonButtons,
  IonIcon,
  IonButton,
  IonBadge,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPopover,
  IonRippleEffect,
  IonAvatar,
  IonImg,
  IonicSlides,
  IonModal,
  IonItemDivider,
  IonCheckbox,
  NavContext
} from "@ionic/react";

import {
  Datepicker,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
  useContext,
} from "react";

import Button from "./../components/Ebert/Button";

import Header from "../components/JobsterHeader";
import ContentBox from "../components/ContentBox";

import { useDebounce, useDebouncedCallback } from "use-debounce";

import { Main } from "../components/HomeScript";
import { isPlatform, ScrollDetail } from "@ionic/core";

import MobiInput from "../components/Mobi/MobiInput";
import { LocationModelAttributes } from "../components/Order/Location";
import { isValidPostCode, onScrollHeader } from "../components/Common";
import MobiPopup from "../components/Mobi/MobiPopup";

import "./Corepetitions.scss";

import JobPost from "../components/Home/JobPost";

import Footer from "../components/Footer";
import { RouteComponentProps, StaticContext, useHistory } from "react-router";
import MobiButton from "../components/Mobi/MobiButton";

import { Link } from "react-router-dom";
import CustomHeaderFade from "../components/CustomHeaderFade";
import CustomHeaderCondense from "../components/CustomHeaderCondense";
import MobiTextarea from "../components/Mobi/MobiTextarea";
import {
  arrowBack,
  arrowBackCircle,
  arrowBackCircleOutline,
  arrowBackOutline,
  arrowForward,
  calendarOutline,
  cartOutline,
  chevronDown,
  chevronUp,
  contractOutline,
  exitOutline,
  home,
  layersOutline,
  libraryOutline,
  navigate,
  personCircle,
  pricetagOutline,
  star,
  timeOutline,
  trendingDown,
  trendingUp,
} from "ionicons/icons";
import MobiSelect from "../components/Mobi/MobiSelect";
import HeaderToolbarMobile from "../components/Header/HeaderToolbarMobile";

import Chemistry from "../svg/chemia.svg";
import Biology from "../svg/biologia.svg";

import { Pagination, Lazy, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";
import { noAnimation } from "../components/Ebert/Animations";

setOptions({
  theme: "ios",
  themeVariant: "light",
});


const Home: React.FC<RouteComponentProps> = ({ location }) => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });


  const [scheduleOpenIndex, setScheduleOpenIndex] = useState<number[]>([]);

  const ionPopoverRef = useRef<HTMLIonPopoverElement>(null);

  const [segmentValue, setSegmentValue] = useState("buy");

  const segmentContainerRef = useRef<HTMLDivElement>(null);
  const ionHeaderRef = useRef<HTMLIonHeaderElement>(null);

  const [data, setData] = useState<string[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);

  const [choosenPrzedmiot, setChoosenPrzedmiot] = useState(isPlatform("mobile") ? "Dowolny przedmiot" : "Dowolny");

  const [przedmiotySegmentWidth, setPrzedmiotySegmentWidth] = useState(235);

  const [lastTransformY, setLastTransformY] = useState(0);
  const [transformY, setTransformY] = useState(0);

  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [appointmentModalCheck, setAppointmentModalCheck] = useState(false);


  const history = useHistory();

  const days = [
    "Dziś",
    "Jutro",
    "Czw",
    "Pt",
    "Sob",
    "Ndz",
    "Pon"
  ]


  useEffect(() => {

    if (location.hash == "#Appointment" && !showAppointmentModal) {
      // history.replace(location.pathname);
      setShowAppointmentModal(true)
    } else if (location.hash != "#Appointment" && showAppointmentModal) {
      setShowAppointmentModal(false);
    }

  }, [location.hash])


  useEffect(() => {

    switch (choosenPrzedmiot) {
      case "Dowolny przedmiot":
        setPrzedmiotySegmentWidth(235);
        break;
      case "Chemia":
        setPrzedmiotySegmentWidth(135);
        break;
      case "Biologia":
        setPrzedmiotySegmentWidth(145);
        break;
      default:
        console.log("");
    }

  }, [choosenPrzedmiot])

  const pushData = () => {
    const max = data.length + 3;
    const min = max - 3;
    const newData = [];
    for (let i = min; i < max; i++) {
      newData.push("Item" + i);
    }

    setData([...data, ...newData]);
  };

  const loadData = (ev: any) => {
    setTimeout(() => {
      pushData();
      console.log("Loaded data");
      ev.target.complete();
      if (data.length == 1000) {
        setInfiniteDisabled(true);
      }
    }, 5000);
  };

  useIonViewWillEnter(() => {
    pushData();

    segmentContainerRef.current
      ?.querySelectorAll(".segment-single")
      .forEach((_e) => {
        const parsed = _e as HTMLDivElement;
        parsed.style.display = "";
      });

    const e = segmentContainerRef.current?.querySelector(
      ".segment-single" + "." + segmentValue
    ) as HTMLDivElement;

    console.log(e);

    if (e) {
      e.style.display = "block";
    }
  });

  return (
    <IonPage>
      <CustomHeaderFade
        page="Korepetycje"
        position="fixed"
        // transformY={transformY}
        ionHeaderRef={ionHeaderRef}
        title="Lista zleceń"
        logo
        extraButtons={<HeaderToolbarMobile />}
      />

      <IonContent
        id="corepetitions-page-controller"
        className="background-lightgrey slide-transition-leave"
        scrollEvents={false}
        // onTouchStart={(e) => {
        //   setLastTransformY(e.changedTouches[0].clientY);
        // }}
        // onTouchMove={(e) => {
        //   if(isPlatform("mobile") || isPlatform("tablet"))
        //   {
        //     const distance = e.touches[0].clientY - lastTransformY;
        //     if(transformY + distance <= 0 && transformY + distance >= -56)
        //     {
        //       setTransformY(transformY + distance);
        //     }
        //     setLastTransformY(e.changedTouches[0].clientY);
        //   }
        // }}
        onIonScrollEnd={(e) => {
          const target = e.target as any;
          if (target && ionHeaderRef.current) {
            if (target.detail) {
              if (target.detail.scrollTop == 0) {
                setTransformY(0);
                ionHeaderRef.current.style.transform =
                  "translate3d(0px, 0px, 0px)";
              }
            }
          }
        }}
        onIonScroll={(e) => {
          const toolbarHeight = -52;
          if (
            (isPlatform("mobile") || isPlatform("tablet")) &&
            ionHeaderRef.current
          ) {
            const distance = -1 * (e.detail.scrollTop / 2 - lastTransformY);
            if (transformY + distance >= 1 || transformY + distance <= -1) {
              if (
                Math.round(transformY + distance) >= 1 ||
                Math.round(transformY + distance) <= -1
              ) {
                if (
                  transformY + distance <= 0 &&
                  transformY + distance >= toolbarHeight
                ) {
                  setTransformY(transformY + distance);
                  ionHeaderRef.current.style.transform =
                    "translate3d(0px, " +
                    Math.round(Number(transformY) + Number(distance)) +
                    "px, 0px)";
                } else if (
                  transformY + distance < toolbarHeight &&
                  transformY != toolbarHeight
                ) {
                  setTransformY(toolbarHeight);
                  ionHeaderRef.current.style.transform =
                    "translate3d(0px, " + toolbarHeight + "px, 0px)";
                } else if (transformY + distance > 0 && transformY != 0) {
                  setTransformY(0);
                  ionHeaderRef.current.style.transform =
                    "translate3d(0px, 0px, 0px)";
                }
              }
            }
            setLastTransformY(e.detail.scrollTop / 2);
          }
        }}
      >
        <div className="container mb-2 mb-md-4 mt-3 container-filter">
          <div className="row justify-content-end">
            <div
              style={{
                cursor: "pointer",
              }}
              className="col-auto px-6 px-md-2 pb-0 pb-md-0"
              onClick={(e: any) => {
                e.persist();
                setShowPopover({ showPopover: true, event: e });
              }}
            >
              <div
                id="przedmioty-trigger2"
                style={{
                  pointerEvents: "none",
                  width: isPlatform("mobile")
                    ? przedmiotySegmentWidth
                    : "200px",
                }}
              >
                <MobiInput
                  endIconSvg='<svg style="width: 24px; height: 24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path></svg>'
                  required
                  label={isPlatform("mobile") ? undefined : "Przedmiot"}
                  value={choosenPrzedmiot}
                />
              </div>
              <IonPopover
                ref={ionPopoverRef}
                size={isPlatform("mobile") ? undefined : "cover"}
                id="przedmioty-popover"
                reference="trigger"
                trigger="przedmioty-trigger2"
                side="bottom"
                event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() =>
                  setShowPopover({ showPopover: false, event: undefined })
                }
                showBackdrop={false}
              >
                <IonContent>
                  <IonList lines="full">
                    <IonItem
                      color={
                        choosenPrzedmiot == "Dowolny" ||
                        choosenPrzedmiot == "Dowolny przedmiot"
                          ? "primary"
                          : ""
                      }
                      button
                      onClick={() => {
                        isPlatform("mobile")
                          ? setChoosenPrzedmiot("Dowolny przedmiot")
                          : setChoosenPrzedmiot("Dowolny");
                        ionPopoverRef.current?.dismiss();
                      }}
                    >
                      <IonLabel
                        style={{
                          marginLeft: "8px",
                        }}
                      >
                        {isPlatform("mobile") ? "Dowolny przedmiot" : "Dowolny"}
                      </IonLabel>
                    </IonItem>
                    <IonItem
                      color={choosenPrzedmiot == "Chemia" ? "primary" : ""}
                      button
                      onClick={() => {
                        setChoosenPrzedmiot("Chemia");
                        ionPopoverRef.current?.dismiss();
                      }}
                    >
                      <IonLabel>Chemia</IonLabel>
                      <IonIcon slot="start" icon={Chemistry} />
                    </IonItem>
                    <IonItem
                      color={choosenPrzedmiot == "Biologia" ? "primary" : ""}
                      button
                      onClick={() => {
                        setChoosenPrzedmiot("Biologia");
                        ionPopoverRef.current?.dismiss();
                      }}
                    >
                      <IonLabel>Biologia</IonLabel>
                      <IonIcon
                        style={{
                          "--ion-color-contrast": "black",
                        }}
                        slot="start"
                        icon={Biology}
                      />
                    </IonItem>
                  </IonList>
                </IonContent>
              </IonPopover>
            </div>
          </div>
        </div>

        <div className="container pb-4 pb-xl-7 mb-5 list-container mt-4 mt-md-0">
          {/* Card */}
          {data.map((e, i) => {
            return (
              <div className="shadow-basic row mb-6 list-item mx-3 mx-md-0">
                <div className="col-12 col-lg-6">
                  <div className="row justify-content-start">
                    <div className="col-auto d-flex">
                      <IonAvatar>
                        <IonImg src="/assets/img/avatars/avatar-1.jpg" />
                      </IonAvatar>
                      <div className="list-description">
                        <h2 className="name">Jan Kowalski</h2>
                        <p className="category">MATEMATYKA</p>
                        <p className="bio d-none d-md-block">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Vivamus mollis turpis sed bibendum finibus.
                          Fusce in molestie velit. Fusce sodales sem id magna
                          interdum, a dapibus mi mollis. Morbi quis laoreet leo,
                          id aliquam lectus.
                        </p>
                        <p className="d-none d-md-block">
                          <IonItem lines="none" className="mt-1">
                            <IonIcon
                              color="secondary"
                              style={{ marginRight: "10px" }}
                              src={pricetagOutline}
                            />
                            <IonLabel
                              color="secondary"
                              style={{ fontWeight: "500" }}
                            >
                              Cena za godzinę - <strong>100,00 zł</strong>
                            </IonLabel>
                          </IonItem>
                        </p>
                      </div>
                    </div>
                    <p className="bio d-block d-md-none">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Vivamus mollis turpis sed bibendum finibus. Fusce in
                      molestie velit. Fusce sodales sem id magna interdum, a
                      dapibus mi mollis. Morbi quis laoreet leo, id aliquam
                      lectus.
                    </p>
                    <p className="d-block d-md-none mb-0">
                      <IonItem lines="none">
                        <IonIcon
                          color="secondary"
                          style={{ marginRight: "10px" }}
                          src={pricetagOutline}
                        />
                        <IonLabel
                          color="secondary"
                          style={{ fontWeight: "500" }}
                        >
                          Cena za godzinę - <strong>100,00 zł</strong>
                        </IonLabel>
                      </IonItem>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="row justify-content-center d-flex">
                    <Button
                      className={"button-arrow d-none d-lg-block swiper-button-prev-" + i}
                      fill="outline"
                      color="primary"
                      height={43}
                      style={{
                        width: "43px",
                        padding: "0",
                        margin: "auto 0",
                        marginLeft: "20px",
                        visibility: "visible",
                        order: 1,
                      }}
                    >
                      <IonIcon style={{ fontSize: "23px" }} src={arrowBack} />
                    </Button>
                    <Button
                      className={"button-arrow d-none d-lg-block swiper-button-next-" + i}
                      fill="outline"
                      color="primary"
                      height={43}
                      style={{
                        width: "43px",
                        padding: "0",
                        margin: "auto 0",
                        marginRight: "20px",
                        order: 3,
                      }}
                      onClick={() => {
                        const swiperRef = document.querySelector(
                          "[data-swiperref='" + i + "']"
                        ) as any;

                        console.log(swiperRef.slideNext);
                      }}
                    >
                      <IonIcon
                        style={{ fontSize: "23px" }}
                        src={arrowForward}
                      />
                    </Button>
                    {!isPlatform("mobile") || scheduleOpenIndex.includes(i) ? (
                      <Swiper
                        style={{
                          order: 2,
                        }}
                        data-swiperref={i}
                        // className={ (scheduleOpenIndex.includes(i) ? "opened" : "")  }
                        // preloadImages={false}
                        // lazy={{
                        //   loadPrevNext: !isPlatform("mobile"),
                        // }}
                        // initialSlide={isPlatform("mobile") ? 0 : 2}
                        // slideToClickedSlide={true}
                        // centeredSlides={true}
                        // modules={[Pagination, IonicSlides, Lazy]}
                        // pagination={{ clickable: true }}
                        slidesPerView={4}
                        // breakpoints={{
                        //   576: {
                        //     slidesPerView: 4,
                        //   },
                        // }}
                        modules={[Navigation]}
                        navigation={{
                          nextEl: ".swiper-button-next-" + i,
                          prevEl: ".swiper-button-prev-" + i,
                        }}
                        allowTouchMove={isPlatform("mobile")}
                        loop={false}
                        // onClick={(event) => {
                        //   event.slides.map((e) => {
                        //     e.querySelector(".card-container")?.classList.remove(
                        //       "activated-card"
                        //     );
                        //   });
                        //   event.clickedSlide
                        //     .querySelector(".card-container")
                        //     ?.classList.add("activated-card");

                        //   console.log(event.clickedSlide);
                        // }}
                      >
                        {["", "", ""].map(() => {
                          return (
                            <>
                              
                              {
                                days.map((day, index) => {
                                  return(
                                    <SwiperSlide
                                style={{
                                  display: "block",
                                  height: "fit-content",
                                }}
                              >
                                <div>
                                  <p style={{ fontSize: "19px" }}>{day}</p>
                                  <p
                                    style={{
                                      color: "#4c5d78",
                                      fontSize: "15px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    {index} mar
                                  </p>
                                  {["", "", "", "", "", "", ""].map((e) => {
                                    return (
                                      // <>
                                      //   <Button
                                      //     disabled
                                      //     label="12:00"
                                      //     color="medium"
                                      //     fill="clear"
                                      //     height={30}
                                      //     style={{ margin: "0" }}
                                      //     onClick={(e) => console.log(e)}
                                      //   />
                                      //   <Button
                                      //     label="12:00"
                                      //     color="primary"
                                      //     fill="clear"
                                      //     height={30}
                                      //     style={{ margin: "0" }}
                                      //     onClick={(e) => {

                                      //       history.push("#appointment");
                                      //       setShowAppointmentModal(true);

                                      //     }}
                                      //   />
                                      // </>
                                      <>
                                        <button
                                          className="appointment-button appointment-button-primary"
                                          onClick={(e) => {
                                            history.push("#Appointment");
                                            setShowAppointmentModal(true);
                                          }}
                                        >
                                          12:00
                                        </button>
                                        <button
                                          disabled
                                          className="appointment-button appointment-button-disabled"
                                        >
                                          12:00
                                        </button>
                                      </>
                                    );
                                  })}
                                </div>
                              </SwiperSlide>
                                  )
                                })
                              }

                              
                            </>
                          );
                        })}
                      </Swiper>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row justify-content-center mt-3">
                    <div className="col-auto">
                      <Button
                        data-id={i}
                        onClick={(e) => {
                          if (scheduleOpenIndex.includes(i)) {
                            if (isPlatform("mobile")) {
                              setTimeout(() => {
                                setScheduleOpenIndex(
                                  scheduleOpenIndex.filter((e) => {
                                    return e != i;
                                  })
                                );
                              }, 1000);
                            } else {
                              setScheduleOpenIndex(
                                scheduleOpenIndex.filter((e) => {
                                  return e != i;
                                })
                              );
                            }
                          } else {
                            setScheduleOpenIndex([...scheduleOpenIndex, i]);
                          }

                          if (isPlatform("mobile")) {
                            setTimeout(() => {
                              const swiperRef = document.querySelector(
                                "[data-swiperref='" + i + "']"
                              ) as HTMLDivElement;
                              const swiperWrapperRef = document.querySelector(
                                "[data-swiperref='" + i + "'] .swiper-wrapper"
                              ) as HTMLDivElement;

                              if (swiperRef && swiperWrapperRef) {
                                if (swiperRef.classList.contains("opened")) {
                                  swiperRef.style.height = "";
                                  swiperRef.classList.remove("opened");
                                } else {
                                  swiperRef.style.height =
                                    swiperWrapperRef.clientHeight + "px";
                                  swiperRef.classList.add("opened");
                                }
                              }
                            }, 200);
                          } else {
                            const swiperRef = document.querySelector(
                              "[data-swiperref='" + i + "']"
                            ) as HTMLDivElement;
                            const swiperWrapperRef = document.querySelector(
                              "[data-swiperref='" + i + "'] .swiper-wrapper"
                            ) as HTMLDivElement;

                            if (swiperRef && swiperWrapperRef) {
                              if (swiperRef.classList.contains("opened")) {
                                swiperRef.style.height = "";
                                swiperRef.classList.remove("opened");
                              } else {
                                swiperRef.style.height =
                                  swiperWrapperRef.clientHeight + "px";
                                swiperRef.classList.add("opened");
                              }
                            }
                          }
                        }}
                        fill="clear"
                        color="secondary"
                        height={28}
                        style={{
                          width: "auto",
                          padding: "0 5px",
                        }}
                      >
                        <span style={{ fontWeight: "600" }}>
                          {scheduleOpenIndex.includes(i)
                            ? "Ukryj terminy"
                            : isPlatform("mobile")
                            ? "Pokaż terminy"
                            : "Pokaż wszystkie dostępne terminy"}
                        </span>
                        <IonIcon
                          style={{ marginLeft: "5px" }}
                          src={
                            scheduleOpenIndex.includes(i)
                              ? chevronUp
                              : chevronDown
                          }
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <IonModal
            mode={ isPlatform("mobile") ? "ios" : "md" }
            isOpen={showAppointmentModal}
            onIonModalDidDismiss={() => 
            {
              if(location.hash == "#Appointment")
              {
                history.goBack();
              }
            }}
            id="appointment-modal"
            className="corepetitions-page-controller modal1"
            backdropDismiss={true}
          >
            <IonHeader mode="ios" >
              <div className="row appointment-button-back-container">
              <div className="col-auto">
                <Button
                  id="appointment-button-exit"
                  onClick={() => {
                    if(location.hash == "#Appointment")
                    {
                      history.goBack();
                    }
                  }}
                  fill="clear"
                  color="primary"
                  height={28}
                  fontSize={17}
                  style={{
                    width: "auto",
                    padding: "0 5px",
                    marginLeft: "13px",
                    marginRight: "13px",
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                >
                  <IonIcon
                    style={{ marginRight: "10px" }}
                    src={arrowBackOutline}
                  />
                  <span style={{ fontWeight: "600" }}>Powrót do listy</span>
                </Button>
              </div>
            </div>
              </IonHeader>
            <IonContent>
              
            
            <div className="appointment-modal-content">
              <div className="row justify-content-start list-item">
                <div className="col-auto d-flex">
                  <IonAvatar>
                    <IonImg src="/assets/img/avatars/avatar-1.jpg" />
                  </IonAvatar>
                  <div className="list-description">
                    <h2 className="name">Jan Kowalski</h2>
                    <p className="category">MATEMATYKA</p>
                  </div>
                </div>
              </div>

              <div className="mt-4 mb-1">
                <IonList lines="full" className="description">
                <IonItem>
                    
                    <IonLabel color="secondary">Przedmiot</IonLabel>
                    <IonLabel color="secondary">
                      <strong>Matematyka</strong>
                    </IonLabel>
                    <IonIcon color="secondary" src={libraryOutline} />
                  </IonItem>
                  <IonItem>
                    
                    <IonLabel color="secondary">Cena za godzinę</IonLabel>
                    <IonLabel color="secondary">
                      <strong>100,00 zł</strong>
                    </IonLabel>
                    <IonIcon color="secondary" src={pricetagOutline} />
                  </IonItem>
                  <IonItem>
                    
                    <IonLabel color="secondary">Termin</IonLabel>
                    <IonLabel color="secondary">
                      <strong>26.02.2022r.</strong>
                    </IonLabel>
                    <IonIcon color="secondary" src={calendarOutline} />
                  </IonItem>
                  <IonItem lines="none">
                    
                    <IonLabel color="secondary">Godzina</IonLabel>
                    <IonLabel color="secondary">
                      <strong>12:00 - 13:00</strong>
                    </IonLabel>
                    <IonIcon color="secondary" src={timeOutline} />
                  </IonItem>
                </IonList>
              </div>


              <div className="col-12 mt-5">
                <h3>Dane kontaktowe</h3>
                <div className="row mt-2">
                  <div className="col-12 col-md-6">
                    <MobiInput label="Imię" required />
                  </div>
                  <div className="col-12 col-md-6 mt-4 mt-md-0">
                    <MobiInput label="Nazwisko" required />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <MobiInput label="Adres email" required />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <MobiInput label="Numer telefonu" required />
                  </div>
                </div>

                <h3 className="mt-7">Dodatkowe informacje dla korepetytora</h3>
                <div className="row mt-4">
                  <div className="col-12">
                    <MobiTextarea />
                  </div>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-12">
                  <IonItem lines="none">
                    <IonLabel className="checkbox-longtext" style={{ textOverflow: "clip", whiteSpace: "normal" }} >
                      Zgadzam się z regulaminem Experientia
                    </IonLabel>
                    <IonCheckbox
                      mode="md"
                      slot="start"
                      checked={appointmentModalCheck}
                      onIonChange={(e) =>
                        setAppointmentModalCheck(e.detail.checked)
                      }
                    />
                  </IonItem>
                </div>
              </div>

              <div className="row mt-4 buttons-payment-container">
              <div className="col-auto">
                  <Button
                    label="Zobacz regulamin"
                    color="primary"
                    fill="clear"
                    fontSize={18}
                  />
                </div>
                <div className="col-auto">
                  <Button
                    routerLink="/Corepetition/Status"
                    onClick={() => setShowAppointmentModal(false)}
                    label="Przejdź do płatności"
                    color="primary"
                    fontSize={18}
                  />
                </div>
              </div>
            </div>
            </IonContent>
          </IonModal>

          {/* <div className="row justify-content-center">
            <div className="col-md-auto">
              <IonInfiniteScroll
                onIonInfinite={loadData}
                threshold={isPlatform("mobile") ? "1000px" : "600px"}
                disabled={isInfiniteDisabled}
              >
                <IonInfiniteScrollContent
                  loadingSpinner="crescent"
                  loadingText="Pobieranie kart..."
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </div>
          </div> */}
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
