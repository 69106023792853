import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonSegment,
  IonSegmentButton,
  IonRadioGroup,
  IonRadio,
  IonAccordionGroup,
  IonList,
  IonAccordion,
  IonButtons,
  IonIcon,
  IonButton,
  IonBadge,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPopover,
  IonRippleEffect,
} from "@ionic/react";

import {
  Datepicker,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
} from "react";

import Button from "./../components/Ebert/Button";

import Header from "../components/JobsterHeader";
import ContentBox from "../components/ContentBox";

import { useDebounce, useDebouncedCallback } from "use-debounce";

import { Main } from "../components/HomeScript";
import { isPlatform, ScrollDetail } from "@ionic/core";

import MobiInput from "../components/Mobi/MobiInput";
import { LocationModelAttributes } from "../components/Order/Location";
import { isValidPostCode, onScrollHeader } from "../components/Common";
import MobiPopup from "../components/Mobi/MobiPopup";

import "./List.scss";

import JobPost from "../components/Home/JobPost";

import Footer from "../components/Footer";
import { useHistory } from "react-router";
import MobiButton from "../components/Mobi/MobiButton";

import { Link } from "react-router-dom";
import CustomHeaderFade from "../components/CustomHeaderFade";
import CustomHeaderCondense from "../components/CustomHeaderCondense";
import MobiTextarea from "../components/Mobi/MobiTextarea";
import {
  cartOutline,
  contractOutline,
  home,
  layersOutline,
  personCircle,
  star,
  trendingDown,
  trendingUp,
} from "ionicons/icons";
import MobiSelect from "../components/Mobi/MobiSelect";
import HeaderToolbarMobile from "../components/Header/HeaderToolbarMobile";

import Chemistry from "../svg/chemia.svg";
import Biology from "../svg/biologia.svg";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

const Home: React.FC = () => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  const [selected, setSelected] = useState("kurs");

  const [noteTaking, setNoteTaking] = useState(false);

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const ionPopoverRef = useRef<HTMLIonPopoverElement>(null);

  type NotesProps = {
    id: number;
    note: string;
    buttonTime: string;
    buttonText: string;
    date: string;
  };

  const [notes, setNotes] = useState<NotesProps[]>([]);

  const [newNoteText, setNewNoteText] = useState("");

  const [segmentValue, setSegmentValue] = useState("buy");

  const [showHeaderSegment, setShowHeaderSegment] = useState(false);

  const segmentContainerRef = useRef<HTMLDivElement>(null);
  const ionSegmentRef = useRef<HTMLIonSegmentElement>(null);
  const ionSegmentHeaderRef = useRef<HTMLIonSegmentElement>(null);
  const ionHeaderRef = useRef<HTMLIonHeaderElement>(null);

  const [data, setData] = useState<string[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);

  const [choosenPrzedmiot, setChoosenPrzedmiot] = useState((isPlatform("mobile") || isPlatform("tablet")) ? "Dowolny przedmiot" : "Dowolny");

  const [przedmiotySegmentWidth, setPrzedmiotySegmentWidth] = useState(235);

  const [lastTransformY, setLastTransformY] = useState(0);
  const [transformY, setTransformY] = useState(0);

  useEffect(() => {

    switch (choosenPrzedmiot) {
      case "Dowolny przedmiot":
        setPrzedmiotySegmentWidth(235);
        break;
      case "Chemia":
        setPrzedmiotySegmentWidth(135);
        break;
      case "Biologia":
        setPrzedmiotySegmentWidth(145);
        break;
      default:
        console.log("");
    }

  }, [choosenPrzedmiot])

  const pushData = () => {
    const max = data.length + 9;
    const min = max - 9;
    const newData = [];
    for (let i = min; i < max; i++) {
      newData.push("Item" + i);
    }

    setData([...data, ...newData]);
  };

  const loadData = (ev: any) => {
    setTimeout(() => {
      pushData();
      console.log("Loaded data");
      ev.target.complete();
      if (data.length == 1000) {
        setInfiniteDisabled(true);
      }
    }, 5000);
  };

  useIonViewWillEnter(() => {
    pushData();

    segmentContainerRef.current
      ?.querySelectorAll(".segment-single")
      .forEach((_e) => {
        const parsed = _e as HTMLDivElement;
        parsed.style.display = "";
      });

    const e = segmentContainerRef.current?.querySelector(
      ".segment-single" + "." + segmentValue
    ) as HTMLDivElement;

    console.log(e);

    if (e) {
      e.style.display = "block";
    }
  });

  return (
    <IonPage>
      <CustomHeaderFade
        page={"Nasze kursy"}
        position="fixed"
        // transformY={transformY}
        ionHeaderRef={ionHeaderRef}
        title="Lista zleceń"
        logo
        extraButtons={<HeaderToolbarMobile />}
        segment={
          (isPlatform("mobile") || isPlatform("tablet")) ? (
            <IonToolbar>
              <IonSegment
                ref={ionSegmentRef}
                value={segmentValue}
                className="px-3"
                mode="md"
                scrollable
                onIonChange={(event) => {
                  setSegmentValue(
                    event.detail.value ? event.detail.value : "buy"
                  );

                  if (segmentContainerRef.current) {
                    segmentContainerRef.current
                      .querySelectorAll(".segment-single")
                      .forEach((_e) => {
                        const parsed = _e as HTMLDivElement;
                        parsed.style.display = "";
                      });

                    const e = segmentContainerRef.current.querySelector(
                      ".segment-single" + "." + event.detail.value
                    ) as HTMLDivElement;

                    console.log(e);

                    if (e) {
                      e.style.display = "block";
                    }

                    setTimeout(() => {
                      segmentContainerRef.current
                        ?.querySelector("#under-segment")
                        ?.scrollIntoView({ block: "start" });
                    }, 10);
                  }
                }}
              >
                <IonSegmentButton value="buy">
                  <IonLabel>DZIAŁY</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="lessons">
                  <IonLabel>KURSY</IonLabel>
                </IonSegmentButton>
                <div
                  style={{
                    cursor: "pointer",
                    marginTop: "5px",
                    marginLeft: "5px",
                  }}
                  onClick={(e: any) => {
                    e.persist();
                    setShowPopover({ showPopover: true, event: e });
                  }}
                >
                  <div
                    id="przedmioty-trigger"
                    style={{
                      pointerEvents: "none",
                      width: przedmiotySegmentWidth + "px",
                    }}
                  >
                    <MobiInput
                      style={{
                        height: "40px"
                      }}
                      endIconSvg='<svg style="width: 24px; height: 24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path></svg>'
                      required
                      label={(isPlatform("mobile") || isPlatform("tablet")) ? undefined : "Przedmiot"}
                      value={choosenPrzedmiot}
                    />
                  </div>
                </div>
              </IonSegment>
            </IonToolbar>
          ) : (
            <></>
          )
        }
      />

      <IonContent
        forceOverscroll={true}
        id="list-courses-page-controller"
        className="background-lightgrey slide-transition-leave"
        scrollEvents={true}
        style={{
          "--padding-top": "109px",
        }}
        
        // onTouchStart={(e) => {
        //   setLastTransformY(e.changedTouches[0].clientY);
        // }}
        // onTouchMove={(e) => {
        //   if((isPlatform("mobile") || isPlatform("tablet")))
        //   {
        //     const distance = e.touches[0].clientY - lastTransformY;
        //     if(transformY + distance <= 0 && transformY + distance >= -56)
        //     {
        //       setTransformY(transformY + distance);
        //     }
        //     setLastTransformY(e.changedTouches[0].clientY);
        //   }
        // }}
        onIonScrollEnd={(e) => {
          const target = e.target as any;
          if(target && ionHeaderRef.current)
          {
            if(target.detail)
            {
              if(target.detail.scrollTop == 0)
              {
                setTransformY(0);
                ionHeaderRef.current.style.transform = "translate3d(0px, 0px, 0px)";
              }
            }
          }
        }}
        onIonScroll={(e) => {
          const toolbarHeight = -52;
          if (((isPlatform("mobile") || isPlatform("tablet"))) && ionHeaderRef.current) {
            const distance = -1 * ((e.detail.scrollTop/2) - lastTransformY);
            if(transformY + distance >= 1 || transformY + distance <= -1)
            {
              if(Math.round(transformY + distance) >= 1 || Math.round(transformY + distance) <= -1)
              {
                if (transformY + distance <= 0 && transformY + distance >= toolbarHeight) {
                  setTransformY(transformY + distance);
                  ionHeaderRef.current.style.transform = "translate3d(0px, " + Math.round(Number(transformY) + Number(distance)) + "px, 0px)";
                }
                else if(transformY + distance < toolbarHeight && transformY != toolbarHeight)
                {
                  setTransformY(toolbarHeight);
                  ionHeaderRef.current.style.transform = "translate3d(0px, " + toolbarHeight + "px, 0px)";
                }
                else if(transformY + distance > 0 && transformY != 0)
                {
                  setTransformY(0);
                  ionHeaderRef.current.style.transform = "translate3d(0px, 0px, 0px)";
                }
              }
            }
            setLastTransformY(e.detail.scrollTop/2);
          }
        }}
      >
        <div className="container mb-2 mb-md-4 mt-3 container-filter d-none d-lg-block">
          <div className="row justify-content-end">
            <div
              style={{
                cursor: "pointer",
              }}
              className="col-12 col-md-auto px-6 px-md-2 pb-0 pb-md-0"
              onClick={(e: any) => {
                e.persist();
                setShowPopover({ showPopover: true, event: e });
              }}
            >
              <div
                id="przedmioty-trigger"
                style={{
                  pointerEvents: "none",
                  width: "200px",
                }}
              >
                <MobiInput
                  endIconSvg='<svg style="width: 24px; height: 24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"></path></svg>'
                  required
                  label={(isPlatform("mobile") || isPlatform("tablet")) ? undefined : "Przedmiot"}
                  value={choosenPrzedmiot}
                />
              </div>
              <IonPopover
                ref={ionPopoverRef}
                size={(isPlatform("mobile") || isPlatform("tablet")) ? undefined : "cover"}
                id="przedmioty-popover"
                reference="trigger"
                trigger="przedmioty-trigger"
                side="bottom"
                event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() =>
                  setShowPopover({ showPopover: false, event: undefined })
                }
                showBackdrop={false}
              >
                <IonContent>
                  <IonList lines="full">
                    <IonItem
                      color={
                        choosenPrzedmiot == "Dowolny" ||
                        choosenPrzedmiot == "Dowolny przedmiot"
                          ? "primary"
                          : ""
                      }
                      button
                      onClick={() => {
                        (isPlatform("mobile") || isPlatform("tablet"))
                          ? setChoosenPrzedmiot("Dowolny przedmiot")
                          : setChoosenPrzedmiot("Dowolny");
                        ionPopoverRef.current?.dismiss();
                      }}
                    >
                      <IonLabel
                        style={{
                          marginLeft: "8px",
                        }}
                      >
                        {(isPlatform("mobile") || isPlatform("tablet")) ? "Dowolny przedmiot" : "Dowolny"}
                      </IonLabel>
                    </IonItem>
                    <IonItem
                      color={choosenPrzedmiot == "Chemia" ? "primary" : ""}
                      button
                      onClick={() => {
                        setChoosenPrzedmiot("Chemia");
                        ionPopoverRef.current?.dismiss();
                      }}
                    >
                      <IonLabel>Chemia</IonLabel>
                      <IonIcon slot="start" icon={Chemistry} />
                    </IonItem>
                    <IonItem
                      color={choosenPrzedmiot == "Biologia" ? "primary" : ""}
                      button
                      onClick={() => {
                        setChoosenPrzedmiot("Biologia");
                        ionPopoverRef.current?.dismiss();
                      }}
                    >
                      <IonLabel>Biologia</IonLabel>
                      <IonIcon
                        style={{
                          "--ion-color-contrast": "black",
                        }}
                        slot="start"
                        icon={Biology}
                      />
                    </IonItem>
                  </IonList>
                </IonContent>
              </IonPopover>
            </div>
          </div>
        </div>

        <div className="container pb-4 pb-xl-7 mt-3">
          <div className="row justify-content-center d-none d-lg-flex">
            <div className="col-auto">
              <IonSegment
                ref={ionSegmentRef}
                value={segmentValue}
                className="mb-4 px-3"
                mode="md"
                scrollable
                onIonChange={(event) => {
                  setSegmentValue(
                    event.detail.value ? event.detail.value : "buy"
                  );

                  if (segmentContainerRef.current) {
                    segmentContainerRef.current
                      .querySelectorAll(".segment-single")
                      .forEach((_e) => {
                        const parsed = _e as HTMLDivElement;
                        parsed.style.display = "";
                      });

                    const e = segmentContainerRef.current.querySelector(
                      ".segment-single" + "." + event.detail.value
                    ) as HTMLDivElement;

                    console.log(e);

                    if (e) {
                      e.style.display = "block";
                    }

                    setTimeout(() => {
                      segmentContainerRef.current
                        ?.querySelector("#under-segment")
                        ?.scrollIntoView({ block: "start" });
                    }, 10);
                  }
                }}
              >
                <IonSegmentButton style={{ width: "500px", fontSize: "17px" }} value="buy">
                  <IonLabel>DZIAŁY</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton style={{ width: "500px", fontSize: "17px" }} value="lessons">
                  <IonLabel>KURSY</IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </div>
          </div>

          <div className="row row-cols-md-2 row-cols-xl-3 mb-6 mb-xl-3 px-2">
            {/* Card */}

            {
              (isPlatform("mobile") || isPlatform("tablet"))
              ?
              data.map(() => {
                return (
                  <div className="col-md pb-3 pb-lg-7">
                    <IonItem
                      className="shadow-basic"
                      routerLink="/lesson"
                      lines="none"
                      style={{
                        "--inner-padding-end": "0px",
                        "--padding-start": "0px",
                        margin: "10px 15px",
                        // boxShadow: "rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px",
                        borderRadius: "8px"
                      }}
                    >
                      <div className="card" style={{ borderRadius: "0" }}>
                        <div className="card-zoom position-relative" >
                          <a
                            href="./course-single-v2.html"
                            className="d-block"
                          >
                            <img
                              className="shadow-light-lg"
                              src="assets/img/products/product-1.jpg"
                              alt="..."
                              style={{ width: "100%" }}
                            />
                          </a>
                        </div>
                        <div
                          className="card-footer"
                          style={{
                            padding: "9px 23px 10px",
                            // backdropFilter: "blur(3px)",
                            // backgroundColor: "rgba(255, 255, 255, 0.8)",
                            backgroundColor: "#fbfbfb",
                          }}
                        >
                          <div
                            style={{
                              display: "none",
                              pointerEvents: "none",
                              position: "fixed",
                              right: 0,
                              bottom: 90,
                              width: "100%",
                              height: 30,
                              zIndex: 3,
                              backdropFilter: "blur(4px)",
                              background:
                                "linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0))",
                            }}
                          />
                          <a
                            href="./instructors-single.html"
                            className="d-block"
                          >
                            <div className="avatar sk-fade-right avatar-xl badge-float position-absolute top-0 right-0 mt-n6 me-5 rounded-circle shadow border border-white border-w-lg">
                              <img
                                src="assets/img/avatars/avatar-1.jpg"
                                alt="..."
                                className="avatar-img rounded-circle"
                              />
                            </div>
                          </a>
                          <div className="row mx-n2 align-items-end" style={{}}>
                            <div className="col px-2">
                              <span
                                className="mb-0 d-inline-block"
                                style={{ color: "#686868" }}
                              >
                                Matematyka
                              </span>
                            </div>
                            <div className="col-auto px-2 text-right">
                              <div
                                className="star-rating mb-0 mb-lg-0 me-lg-3"
                                style={{
                                  textShadow: "none",
                                }}
                              >
                                <div
                                  className="rating"
                                  style={{ width: "50%" }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="position-relative">
                            <a
                              href="./course-single-v2.html"
                              className="d-block stretched-link mb-0"
                            >
                              <h3
                                className="line-clamp-2 h-md-48 h-lg-58 me-md-6 me-lg-10 me-xl-4 mb-0"
                                style={{
                                  fontWeight: 600,
                                  letterSpacing: "0.5px",
                                }}
                              >
                                Trygonometria
                              </h3>
                            </a>
                            <div className="row mx-n2 align-items-end">
                              <div className="col px-2">
                                <ul className="nav mx-n3">
                                  <li className="nav-item px-3">
                                    <div className="d-flex align-items-center">
                                      <div className="me-2 d-flex icon-uxs text-secondary">
                                        <svg
                                          width={20}
                                          height={20}
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M17.1947 7.06802L14.6315 7.9985C14.2476 7.31186 13.712 6.71921 13.0544 6.25992C12.8525 6.11877 12.6421 5.99365 12.4252 5.88303C13.0586 5.25955 13.452 4.39255 13.452 3.43521C13.452 1.54098 11.9124 -1.90735e-06 10.0197 -1.90735e-06C8.12714 -1.90735e-06 6.58738 1.54098 6.58738 3.43521C6.58738 4.39255 6.98075 5.25955 7.61414 5.88303C7.39731 5.99365 7.1869 6.11877 6.98502 6.25992C6.32752 6.71921 5.79178 7.31186 5.40787 7.9985L2.8447 7.06802C2.33612 6.88339 1.79688 7.26044 1.79688 7.80243V16.5178C1.79688 16.8465 2.00256 17.14 2.31155 17.2522L9.75312 19.9536C9.93073 20.018 10.1227 20.0128 10.2863 19.9536L17.7278 17.2522C18.0368 17.14 18.2425 16.8465 18.2425 16.5178V7.80243C18.2425 7.26135 17.704 6.88309 17.1947 7.06802ZM10.0197 1.5625C11.0507 1.5625 11.8895 2.40265 11.8895 3.43521C11.8895 4.46777 11.0507 5.30792 10.0197 5.30792C8.98866 5.30792 8.14988 4.46777 8.14988 3.43521C8.14988 2.40265 8.98866 1.5625 10.0197 1.5625ZM9.23844 18.1044L3.35938 15.9703V8.91724L9.23844 11.0513V18.1044ZM10.0197 9.67255L6.90644 8.54248C7.58164 7.51892 8.75184 6.87042 10.0197 6.87042C11.2875 6.87042 12.4577 7.51892 13.1329 8.54248L10.0197 9.67255ZM16.68 15.9703L10.8009 18.1044V11.0513L16.68 8.91724V15.9703Z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </div>
                                      <div className="font-size-sm">
                                        5 lessons
                                      </div>
                                    </div>
                                  </li>
                                  <li className="nav-item px-3">
                                    <div className="d-flex align-items-center">
                                      <div className="me-2 d-flex icon-uxs text-secondary">
                                        <svg
                                          width={16}
                                          height={16}
                                          viewBox="0 0 16 16"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M14.3164 4.20996C13.985 4.37028 13.8464 4.76904 14.0067 5.10026C14.4447 6.00505 14.6667 6.98031 14.6667 8C14.6667 11.6759 11.6759 14.6667 8 14.6667C4.32406 14.6667 1.33333 11.6759 1.33333 8C1.33333 4.32406 4.32406 1.33333 8 1.33333C9.52328 1.33333 10.9543 1.83073 12.1387 2.77165C12.4259 3.00098 12.846 2.95296 13.0754 2.66471C13.3047 2.37663 13.2567 1.95703 12.9683 1.72803C11.5661 0.613607 9.8016 0 8 0C3.58903 0 0 3.58903 0 8C0 12.411 3.58903 16 8 16C12.411 16 16 12.411 16 8C16 6.77767 15.7331 5.60628 15.2067 4.51969C15.0467 4.18766 14.6466 4.04932 14.3164 4.20996Z"
                                            fill="currentColor"
                                          />
                                          <path
                                            d="M7.99967 2.66663C7.63167 2.66663 7.33301 2.96529 7.33301 3.33329V7.99996C7.33301 8.36796 7.63167 8.66663 7.99967 8.66663H11.333C11.701 8.66663 11.9997 8.36796 11.9997 7.99996C11.9997 7.63196 11.701 7.33329 11.333 7.33329H8.66634V3.33329C8.66634 2.96529 8.36768 2.66663 7.99967 2.66663Z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </div>
                                      <div className="font-size-sm">8h 12m</div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-auto px-2 text-right">
                                <ins
                                  className="h4 mb-0 d-block mb-lg-n1"
                                  style={{ fontSize: 19 }}
                                >
                                  499,00 zł
                                </ins>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </IonItem>
                  </div>
                );
              })
              :
              data.map(() => {
                return (
                  <div className="col-md pb-4 pb-md-7">
                    <IonItem
                      className="border shadow"
                      routerLink="/lesson"
                      lines="none"
                      style={{
                        "--inner-padding-start": "0",
                        "--inner-padding-end": "0",
                        "--padding-start": "0",
                        "--padding-end": "0",
                      }}
                    >
                      <div className={"card border shadow p-2 sk-fade"}>
                        <div className="card-zoom position-relative">
                          <a
                            href="./course-single-v2.html"
                            className="card-img sk-thumbnail d-block"
                          >
                            <img
                              className="rounded shadow-light-lg"
                              src="assets/img/products/product-1.jpg"
                              alt="..."
                            />
                          </a>
                          <span className="badge sk-fade-bottom badge-lg badge-orange badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                            <span className="text-white text-uppercase fw-bold font-size-xs">
                              BEST SELLER
                            </span>
                          </span>
                        </div>
                        {/* Footer */}
                        <div className="card-footer px-2 pb-2 mb-1 pt-4 position-relative">
                          <a href="./instructors-single.html" className="d-block">
                            <div className="avatar sk-fade-right avatar-xl badge-float position-absolute top-0 right-0 mt-n6 me-5 rounded-circle shadow border border-white border-w-lg">
                              <img
                                src="assets/img/avatars/avatar-1.jpg"
                                alt="..."
                                className="avatar-img rounded-circle"
                              />
                            </div>
                          </a>
                          {/* Preheading */}
                          <a href="./course-single-v2.html">
                            <span className="mb-1 d-inline-block text-gray-800">
                              Photography
                            </span>
                          </a>
                          {/* Heading */}
                          <div className="position-relative">
                            <a
                              href="./course-single-v2.html"
                              className="d-block stretched-link"
                            >
                              <h4 className="line-clamp-2 h-md-48 h-lg-58 me-md-6 me-lg-10 me-xl-4 mb-2">
                                Fashion Photography From Professional
                              </h4>
                            </a>
                            <div className="d-lg-flex align-items-end flex-wrap mb-n1">
                              <div className="star-rating mb-2 mb-lg-0 me-lg-3">
                                <div
                                  className="rating"
                                  style={{ width: "50%" }}
                                />
                              </div>
                            </div>
                            <div className="row mx-n2 align-items-end" style={{ marginTop: "-25px" }} >
                              <div className="col px-2">
                                <ul className="nav mx-n3">
                                  <li className="nav-item px-3">
                                    <div className="d-flex align-items-center">
                                      <div className="me-2 d-flex icon-uxs text-secondary">
                                        {/* Icon */}
                                        <svg
                                          width={20}
                                          height={20}
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M17.1947 7.06802L14.6315 7.9985C14.2476 7.31186 13.712 6.71921 13.0544 6.25992C12.8525 6.11877 12.6421 5.99365 12.4252 5.88303C13.0586 5.25955 13.452 4.39255 13.452 3.43521C13.452 1.54098 11.9124 -1.90735e-06 10.0197 -1.90735e-06C8.12714 -1.90735e-06 6.58738 1.54098 6.58738 3.43521C6.58738 4.39255 6.98075 5.25955 7.61414 5.88303C7.39731 5.99365 7.1869 6.11877 6.98502 6.25992C6.32752 6.71921 5.79178 7.31186 5.40787 7.9985L2.8447 7.06802C2.33612 6.88339 1.79688 7.26044 1.79688 7.80243V16.5178C1.79688 16.8465 2.00256 17.14 2.31155 17.2522L9.75312 19.9536C9.93073 20.018 10.1227 20.0128 10.2863 19.9536L17.7278 17.2522C18.0368 17.14 18.2425 16.8465 18.2425 16.5178V7.80243C18.2425 7.26135 17.704 6.88309 17.1947 7.06802ZM10.0197 1.5625C11.0507 1.5625 11.8895 2.40265 11.8895 3.43521C11.8895 4.46777 11.0507 5.30792 10.0197 5.30792C8.98866 5.30792 8.14988 4.46777 8.14988 3.43521C8.14988 2.40265 8.98866 1.5625 10.0197 1.5625ZM9.23844 18.1044L3.35938 15.9703V8.91724L9.23844 11.0513V18.1044ZM10.0197 9.67255L6.90644 8.54248C7.58164 7.51892 8.75184 6.87042 10.0197 6.87042C11.2875 6.87042 12.4577 7.51892 13.1329 8.54248L10.0197 9.67255ZM16.68 15.9703L10.8009 18.1044V11.0513L16.68 8.91724V15.9703Z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </div>
                                      <div className="font-size-sm">
                                        5 lessons
                                      </div>
                                    </div>
                                  </li>
                                  <li className="nav-item px-3">
                                    <div className="d-flex align-items-center">
                                      <div className="me-2 d-flex icon-uxs text-secondary">
                                        {/* Icon */}
                                        <svg
                                          width={16}
                                          height={16}
                                          viewBox="0 0 16 16"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M14.3164 4.20996C13.985 4.37028 13.8464 4.76904 14.0067 5.10026C14.4447 6.00505 14.6667 6.98031 14.6667 8C14.6667 11.6759 11.6759 14.6667 8 14.6667C4.32406 14.6667 1.33333 11.6759 1.33333 8C1.33333 4.32406 4.32406 1.33333 8 1.33333C9.52328 1.33333 10.9543 1.83073 12.1387 2.77165C12.4259 3.00098 12.846 2.95296 13.0754 2.66471C13.3047 2.37663 13.2567 1.95703 12.9683 1.72803C11.5661 0.613607 9.8016 0 8 0C3.58903 0 0 3.58903 0 8C0 12.411 3.58903 16 8 16C12.411 16 16 12.411 16 8C16 6.77767 15.7331 5.60628 15.2067 4.51969C15.0467 4.18766 14.6466 4.04932 14.3164 4.20996Z"
                                            fill="currentColor"
                                          />
                                          <path
                                            d="M7.99967 2.66663C7.63167 2.66663 7.33301 2.96529 7.33301 3.33329V7.99996C7.33301 8.36796 7.63167 8.66663 7.99967 8.66663H11.333C11.701 8.66663 11.9997 8.36796 11.9997 7.99996C11.9997 7.63196 11.701 7.33329 11.333 7.33329H8.66634V3.33329C8.66634 2.96529 8.36768 2.66663 7.99967 2.66663Z"
                                            fill="currentColor"
                                          />
                                        </svg>
                                      </div>
                                      <div className="font-size-sm">8h 12m</div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-auto px-2 text-right">
                                <del className="font-size-sm">$959</del>
                                <ins className="h4 mb-0 d-block mb-lg-n1">
                                  $415.99
                                </ins>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </IonItem>
                  </div>
                );
              })
            }
          </div>
          {/* <div className="row justify-content-center">
            <div className="col-md-auto">
              <IonInfiniteScroll
                onIonInfinite={loadData}
                threshold={(isPlatform("mobile") || isPlatform("tablet")) ? "1000px" : "600px"}
                disabled={isInfiniteDisabled}
              >
                <IonInfiniteScrollContent
                  loadingSpinner="crescent"
                  loadingText="Pobieranie kart..."
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </div>
          </div> */}
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
