import { AnimationBuilder, IonButton, IonCol, IonGrid, IonImg, IonRow, isPlatform } from "@ionic/react";
import { AnyKindOfDictionary } from "lodash";
import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  forwardRef,
  MouseEventHandler,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { ButtonLoader, OnKeyDownHours, Spinner } from "../Common";

import './Button.css';

const ImageAspectRationWidth = (width: number, height: number, value: number) => {
  return (width/height)*((height/width) * value)
}

const ImageAspectRationHeight = (width: number, height: number, value: number) => {
  return (height/width) * value
}

type Props = {
  width: number;
  height: number;
  className: string;
  onIonImgWillLoad?: ((event: CustomEvent<void>) => void) | undefined;
  src: string;
  alt?: string;
}

// const _responsive = {
//   xsmall: {
//     className: "input-primary",
//   },
//   medium: {
//     className: "input-bold",
//   },
// }


const Image: React.FC<Props> = ({
  width,
  height,
  className,
  onIonImgWillLoad,
  src,
  alt
}) => {
  return (
    <IonImg
      onIonImgWillLoad={(e) => {

        if(onIonImgWillLoad)
        {
          onIonImgWillLoad(e);
        }
        
        const target = e.target as HTMLIonImgElement;
        target.style.width = ImageAspectRationWidth(width, height, target.clientWidth) + "px"
        target.style.height = ImageAspectRationHeight(width, height, target.clientWidth) + "px"

      }}
      className={className}
      src={src}
      alt={alt}
    />
  );
};

export default Image;
