import {
  IonAccordion,
  IonAccordionGroup,
  IonApp,
  IonContent,
  IonFooter,
  IonIcon,
  IonImg,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRippleEffect,
  IonRouterLink,
  useIonViewWillEnter,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { home, list, add, desktop, school, homeOutline, desktopOutline, schoolOutline, personOutline, calendarOutline, documentOutline, personCircle } from "ionicons/icons";
import { RefObject, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import CustomHeaderFade from "./CustomHeaderFade";

import "./JobsterMenu.css";

interface AppPage {
  url: string;
  title: string;
  icon: string;
  exact: boolean;
}

const appPages: AppPage[] = [
  {
    title: "Strona główna",
    url: "/",
    icon: homeOutline,
    exact: true
  },
  {
    title: "Nasze kursy",
    url: "/List",
    icon: desktopOutline,
    exact: true
  },
  {
    title: "Korepetycje",
    url: "/Corepetitions",
    icon: schoolOutline,
    exact: true
  },
];

const appPagesProfile: AppPage[] = [
  {
    title: "Profil",
    url: "/profile/data",
    icon: personOutline,
    exact: true
  },
  {
    title: "Kursy",
    url: "/profile/courses",
    icon: desktopOutline,
    exact: true
  },
  {
    title: "Korepetycje",
    url: "/profile/corepetition",
    icon: calendarOutline,
    exact: true
  },
  {
    title: "Notatki",
    url: "/profile/notes",
    icon: documentOutline,
    exact: true
  },
];

const Menu: React.FC = () => {
  const history = useHistory();

  const [url, setUrl] = useState("");


  return (
    <IonMenu
      className="blur"
      style={{
        "--width": "250px",
      }}
      id="mainMenu"
      contentId="main"
      type="overlay"
      maxEdgeStart={0}
      onIonWillOpen={() => {
        setUrl(history.location.pathname);
        console.log(history.location.pathname)
      }}
    >
      {/* <CustomHeaderFade logo extended={false} >
      Menu
    </CustomHeaderFade> */}

      <div className="fade-header" style={{ padding: "15px 25px 0 25px" }}>
        <IonRouterLink routerLink="/">
          <a className="navbar-brand me-0">
            <IonImg
              src="./assets/img/logo-hat.svg"
              className="navbar-brand-img"
              alt="..."
              style={{ 
                height: "42px",
                width: "fit-content"
               }}
            />
          </a>
        </IonRouterLink>
      </div>

      <IonContent fullscreen style={{ position: "relative" }} id="menu-content">
        <IonList
          lines="none"
          style={{
            padding: "15px 20px 0px",
          }}
        >
          {appPages.map((e, i) => {
            return (
              <IonItem
                color={e.url == url ? "primary" : undefined}
                routerLink={e.url == url ? undefined : e.url}
                onClick={() => {
                  const el = document.getElementById("mainMenu") as any;
                  if (el) {
                    el.close();
                  }
                }}
              >
                <IonLabel>{e.title}</IonLabel>
                <IonIcon slot="start" icon={e.icon} />
              </IonItem>
            );
          })}
        </IonList>

        <IonItemDivider style={{ "min-height": "1px", marginTop: "15px" }} />

        <IonItem
        lines="none"
        style={{
          "--background": "#f2fbfb",
          borderTop: "3px solid var(--ion-color-primary)",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <IonIcon
          color="secondary"
          style={{
            width: "40px",
            height: "40px",
            marginRight: "16px",
          }}
          slot="start"
          src={personCircle}
        ></IonIcon>
        <IonLabel color="secondary">
          <h2
            style={{
              fontWeight: 600,
            }}
          >
            Mateusz Ebert
          </h2>
          <h3>mateusz@ebert.app</h3>
        </IonLabel>
      </IonItem>

        <IonList
          lines="none"
          style={{
            padding: "15px 20px 0px",
          }}
        >
          {appPagesProfile.map((e, i) => {
            return (
              <IonItem
                color={e.url == url ? "primary" : undefined}
                routerLink={e.url == url ? undefined : e.url}
                onClick={() => {
                  const el = document.getElementById("mainMenu") as any;
                  if (el) {
                    el.close();
                  }
                }}
              >
                <IonLabel>{e.title}</IonLabel>
                <IonIcon slot="start" icon={e.icon} />
              </IonItem>
            );
          })}
        </IonList>

          {/* <hr/>

        <IonList
          lines="none"
          style={{
            padding: "5px 20px 0px",
          }}
        >
          {appPages.map((e, i) => {
            return (
              <IonItem
                color={e.url == url ? "primary" : undefined}
                routerLink={e.url == url ? undefined : e.url}
                onClick={() => {
                  const el = document.getElementById("mainMenu") as any;
                  if (el) {
                    el.close();
                  }
                }}
              >
                <IonLabel>{e.title}</IonLabel>
                <IonIcon slot="start" icon={e.icon} />
              </IonItem>
            );
          })}
        </IonList> */}

        {/* <IonListHeader mode="ios">
          <IonLabel>List Header</IonLabel>
        </IonListHeader>
        <IonList
          lines="none"
          style={{
            padding: "0 15px",
          }}
        >
          {appPages.map((e, i) => {
            return (
              <IonRouterLink
                routerLink={e.url}
                onClick={() => {
                  const el = document.getElementById("mainMenu") as any;
                  if (el) {
                    el.close();
                  }
                }}
              >
                <IonItem style={{ position: "relative" }}>
                  <IonLabel>{e.title}</IonLabel>
                  <IonIcon slot="end" icon={e.icon} />
                  <IonRippleEffect />
                </IonItem>
              </IonRouterLink>
            );
          })}
        </IonList> */}
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
