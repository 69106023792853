import { IonCol, IonGrid, IonRow, isPlatform } from "@ionic/react";
import { Input, localePl, Popup, Select, setOptions } from "@mobiscroll/react";
import { AnyKindOfDictionary } from "lodash";
import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  Ref,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { OnKeyDownHours, Spinner } from "../Common";

setOptions({
  theme: "ios",
  themeVariant: "light",
  locale: localePl,
});

type Props = {
  label?: string;
  placeholder?: string;
  type?: string;
  autoComplete?: string;
  id?: string;
  responsive?: {[key: string]: any};
  startIcon?: string;
  endIcon?: string;
  endIconSvg?: string;
  startIconSvg?: string;
  startIconSrc?: string;
  endIconSrc?: string;
  loading?: boolean;
  onKeyDown?: (event: KeyboardEvent) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  maxLength?: string;
  step?: string;
  value?: any;
  responsiveDefault?: boolean;
  disabled?: boolean;
  required?: boolean;
  inputmode?: "numeric" | "email" | "decimal" | "tel" | "search" | "url";
  labelBottom?: string;
  onErrorSubmit?: () => void;
  borderless?: boolean;
  inputClass?: string;
  labelStyle?: "stacked" | "inline" | "floating";
  passwordToggle?: boolean;
  scaleWidthByLetters?: boolean;
  style?: React.CSSProperties;
}

type Handle = {
  error?: boolean;
  onErrorSubmit?: () => void;
}

const _responsive = {
  xsmall: {
    className: "input-primary",
  },
  medium: {
    className: "input-bold",
  },
}


const MobiInput: ForwardRefRenderFunction<Handle, Props> = ({
  placeholder,
  label,
  type,
  autoComplete,
  id,
  responsive,
  startIcon,
  endIcon,
  startIconSvg,
  startIconSrc,
  endIconSvg,
  endIconSrc,
  loading,
  onKeyDown,
  onChange,
  onKeyPress,
  onKeyUp,
  error,
  errorMessage,
  className,
  maxLength,
  step,
  value,
  disabled,
  labelBottom,
  onErrorSubmit,
  inputmode,
  inputClass,
  passwordToggle,
  style,
  labelStyle = "floating",
  responsiveDefault = true,
  required = false,
  borderless = false
}, ref: Ref<Props>) => {

  useImperativeHandle(ref, () => ({ error, onErrorSubmit }));


  // const refInput = useRef<Input>(null);

  return (
    <form style={{ position: (!required ? "relative" : "unset"), height: "auto" }} >
    <Input
      style={style}
      // ref={refInput}
      passwordToggle={passwordToggle}
      required={required}
      endIcons={endIcon}
      startIcon={startIcon}
      startIconSrc={startIconSrc}
      startIconSvg={startIconSvg}
      endIconSvg={Boolean(loading) ? Spinner : endIconSvg}
      endIconSrc={endIconSrc}
      disabled={Boolean(loading) || disabled}
      placeholder={placeholder}
      labelStyle={labelStyle}
      theme="ios"
      inputStyle="box"
      label={label}
      type={type}
      autoComplete={autoComplete}
      id={id}
      responsive={
        responsiveDefault
          ? Boolean(responsive)
            ? responsive
            : _responsive
          : undefined
      }
      onKeyDown={onKeyDown}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        if(onChange)
        {
          onChange(e);
        }

        // if (scaleWidthByLetters) {
        //   var inputElement = inst._scroller._input as HTMLInputElement;

        //   if (inputElement) {
        //     inputElement.style.width = args.value.length + 7 + "ch";
        //   }
        // }

        console.log(e);

      }}
      onKeyPress={onKeyPress}
      onKeyUp={onKeyUp}
      error={error}
      errorMessage={errorMessage}
      class={className}
      inputClass={inputClass}
      maxLength={maxLength}
      step={step}
      value={value}
      inputmode={inputmode ? inputmode : ""}
      onFocus={(event: FocusEvent) => {
        const target = event.target as HTMLInputElement;
        if (isPlatform("mobile") && target != null) {
          target.scrollIntoView({ block: "start" });
        }
      }}
    />
    {
      !required && !errorMessage
      ?
      <span className=" mbsc-ios mbsc-ltr mbsc-error-message mbsc-optional-message-box mbsc-error-message-floating" style={{ opacity: ((Boolean(loading) || disabled) ? "0.5" : "0.9") }} >Opcjonalne</span>
      :
      <></>
    }
    </form>
  );
};

export default forwardRef(MobiInput);
