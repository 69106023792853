import { ScrollDetail } from "@ionic/react";
import { RefObject } from "react";

export const SegmentScroll = (
  event: CustomEvent<ScrollDetail>,
  setShowHeaderSegment: (value: React.SetStateAction<boolean>) => void,
  showHeaderSegment: boolean,
  ionSegmentRef: RefObject<HTMLIonSegmentElement>,
  ionHeaderRef: RefObject<HTMLIonHeaderElement>
) => {
  if (ionSegmentRef.current && ionHeaderRef.current) {
    const distance = ionSegmentRef.current.getBoundingClientRect().y;
    const contentRef = event.target as HTMLIonContentElement;

    if (
      distance -
        ionHeaderRef.current.offsetHeight +
        ionSegmentRef.current.offsetHeight >
        0 &&
      showHeaderSegment
    ) {
      setShowHeaderSegment(false);
      contentRef.scrollToPoint(
        0,
        event.detail.scrollTop - ionSegmentRef.current.offsetHeight - 10
      );
    } else if (
      distance - ionHeaderRef.current.offsetHeight <= 0 &&
      !showHeaderSegment
    ) {
      setShowHeaderSegment(true);
      contentRef.scrollToPoint(
        0,
        event.detail.scrollTop + ionSegmentRef.current.offsetHeight + 7
      );
      
    }
  }
};