import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonPopover,
  IonRouterOutlet,
  isPlatform,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
// import "./ionicCSS/padding.css";
// import "./ionicCSS/float-elements.css";
// import "./ionicCSS/text-alignment.css";
// import "./ionicCSS/text-transformation.css";
// import "./ionicCSS/flex-utils.css";
// import "./ionicCSS/display.css";

/* Theme variables */
import "./theme/variables.css";
//import "./theme/dashlite.scss";
import "./theme/fonts.css";
// import "./theme/font-awesome.min.css";
import "./font-awesome/css/all.min.css";

//import "./theme/jobster.scss";
import "./css/skola.css";


import "./theme/animate.scss";

import "./Global.css";
import "./GlobalC.css";
import "./Global.scss";
import "./Global_desktop.css";
import "./Global_mobile.css";
import "./Global_mobile.scss";

import Menu from "./components/JobsterMenu";
import MenuCart from "./components/MenuCart";


import { BaseSyntheticEvent, createElement, useEffect, useRef, useState } from "react";

import Home from "./pages/Home";
import Lesson from "./pages/Lesson";
import List from "./pages/List";
import Login from "./pages/Login";
import Recovery from "./pages/Login/Recovery";
import RecoverySuccess from "./pages/Login/RecoverySuccess";
import Profile from "./pages/Profile";
import RegisterSuccess from "./pages/Login/RegisterSuccess";
import Cart from "./pages/Cart";
import OrderStatus from "./pages/OrderStatus";
import Corepetitions from "./pages/Corepetitions";
import CorepetitionsStatus from "./pages/Corepetitions/Status";



import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./GlobalStateProvider";

import { Storage } from "@ionic/storage";
import { Roles } from "./components/Roles";

//import { SignalR } from "./components/Common";

import { setupIonicReact } from "@ionic/react";
import Header from "./components/JobsterHeader";

import logo from "./zlecenia_svg/logo.svg";

import { mobileAnimation, opacityAnimation } from "./components/Ebert/Animations";
import { calendarOutline, desktopOutline, personCircle, personOutline } from "ionicons/icons";
import Button from "./components/Ebert/Button";
import Startup from "./components/Startup";

setupIonicReact({
  mode: "md",
});


const App: React.FC = () => {
  // const storage = new Storage();
  // const { setState } = useGlobalState();


  // useEffect(() => {
  //   async function CreateStorage() {
  //     await storage.create();
  //   }
  //   async function GetToken() {
  //     await CreateStorage();

  //     const token = await storage.get("token");

  //     if (Boolean(token)) {
  //         setState((prev) => ({
  //         ...prev,
  //           ...{
  //             token: token,
  //             logged: true,
  //             firstname: "siema",
  //             role: Roles.PlatformAdmin,
  //           },
  //         }));
  //     } else {
  //       setState((prev) => ({
  //         ...prev,
  //         ...{ token: undefined, logged: false },
  //       }));
  //     }
  //   }

  //   GetToken();
  //   }, []);

  // useEffect(() => {

  //   window.addEventListener("click", (event) => {

  //     const path = (event as any).path as Element[];
      
  //     const targets = document.querySelectorAll("ion-popover") as NodeListOf<HTMLIonPopoverElement>;

  //     if(path.filter((e) => e.tagName == "ION-POPOVER" || (e.classList == null ? false : e.classList.contains("prevent-dismiss") ) ).length <= 0)
  //     {

  //       targets.forEach((e) => {

  //         e.dismiss();

  //       })

  //     }

  //   }, { once: true })

  // }, [])


  const Routes = () => (
    <>
      <IonRouterOutlet
        id="main"
        animated={isPlatform("mobile")}
        animation={isPlatform("mobile") ? mobileAnimation : undefined}
        mode={"md"}
      >
        <Route path="/" component={Home} exact={true} />
        <Route path="/Lesson" component={Lesson} />
        <Route path="/List" component={List} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/login/recovery" component={Recovery} />
        <Route exact path="/login/recoverysuccess" component={RecoverySuccess} />

        <Route exact path="/register-success" component={RegisterSuccess} />

        <Route path="/profile" component={Profile} />

        <Route exact path="/cart" component={Cart} />
        <Route exact path="/orderstatus" component={OrderStatus} />

        <Route exact path="/Corepetitions" component={Corepetitions} />
        
        <Route exact path="/Corepetition/Status" component={CorepetitionsStatus} />

      </IonRouterOutlet>
    </>
  );

  const JustHeader = () => (
    <>
      <Menu />
      <MenuCart/>
      <Routes />
    </>
  );

  return (
    <IonApp>

      <IonReactRouter>
        <JustHeader />
      </IonReactRouter>
      

      <Startup />
     
    </IonApp>
  );
};

export default App;
