import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonSegment,
  IonSegmentButton,
  IonRadioGroup,
  IonRadio,
  IonAccordionGroup,
  IonList,
  IonAccordion,
  IonButtons,
  IonIcon,
  IonButton,
  IonBadge,
  IonSplitPane,
  IonMenu,
  IonRouterLink,
  IonRippleEffect,
  IonAvatar,
  IonImg,
} from "@ionic/react";

import {
  Datepicker,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
} from "react";

import { isPlatform, ScrollDetail } from "@ionic/core";

import {
  calendarOutline,
  cartOutline,
  contractOutline,
  home,
  layersOutline,
  libraryOutline,
  personCircle,
  pricetagOutline,
  timeOutline,
} from "ionicons/icons";

const Corepetitions: React.FC = () => {

  return (
    <div className="px-5 pt-5 corepetitions-page-controller">
    <IonList lines="none">
      {
        ["", "", "", "", "", ""].map(() => {
          return(
            <IonItem routerLink="/corepetitions/status" className="shadow-basic mb-4 list-item mx-3 mx-lg-0 lift" style={{ "--background-hover": "none", padding: 0  }} >
              <div className="row w-100pr" >
              <div className="col-12 col-lg-6" style={{ margin: "auto" }}>
              <div className="row justify-content-start">
                <div className="col-auto d-flex">
                  <IonAvatar style={{
                    width: "65px",
                    height: "65px",
                    minWidth: "65px",
                  }} >
                    <IonImg src="/assets/img/avatars/avatar-1.jpg" />
                  </IonAvatar>
                  <div className="list-description">
                    <h2 className="name">Jan Kowalski</h2>
                    <p className="category">MATEMATYKA</p>
                   
                  </div>
                </div>
               
              </div>
            </div>
            <div className="col-12 col-lg-6">
            <div>
                <IonList lines="full" className="description">
                <IonItem>
                    
                    <IonLabel color="secondary">Przedmiot</IonLabel>
                    <IonLabel color="secondary">
                      <strong>Matematyka</strong>
                    </IonLabel>
                    <IonIcon color="secondary" src={libraryOutline} />
                  </IonItem>
                  <IonItem>
                    
                    <IonLabel color="secondary">Termin</IonLabel>
                    <IonLabel color="secondary">
                      <strong>26.02.2022r.</strong>
                    </IonLabel>
                    <IonIcon color="secondary" src={calendarOutline} />
                  </IonItem>
                  <IonItem lines="none">
                    
                    <IonLabel color="secondary">Godzina</IonLabel>
                    <IonLabel color="secondary">
                      <strong>12:00 - 13:00</strong>
                    </IonLabel>
                    <IonIcon color="secondary" src={timeOutline} />
                  </IonItem>
                </IonList>
              </div>
            </div>
              </div>
           
          </IonItem>
          )
        })
      }
      
    </IonList>
    </div>
  );
};

export default Corepetitions;
