import {
  IonApp,
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  NavContext,
} from "@ionic/react";
import { cartOutline, closeCircleOutline, closeOutline, pricetag } from "ionicons/icons";
import { useContext, useRef } from "react";
import { NavLink } from "react-router-dom";

import {
  GlobalStateProvider,
  useGlobalState,
  GlobalStateInterface,
} from "./../GlobalStateProvider";
import Button from "./Ebert/Button";

const MenuCart: React.FC = () => {
  const { state, setState } = useGlobalState();
  const { navigate } = useContext(NavContext);

  const menuRef = useRef<HTMLIonMenuElement>(null);

  return (
    <IonMenu className="blur" ref={menuRef} id="cart-menu" side="end" contentId="main" type="overlay" swipeGesture={true} maxEdgeStart={0} >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
          <IonButton style={{ "--padding-start": "0", "--padding-end": "0" }} onClick={() => {

            const el = document.getElementById("cart-menu") as any;

            if (el) {
              el.close();
            }

          }} >
        <IonIcon style={{ width: "30px", height: "30px" }} slot="icon-only" icon={closeOutline} />
      </IonButton>
          </IonButtons>
          <IonTitle>Twój koszyk</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList
          lines="none"
          style={{
            height: "100%",
            padding: "9px 6px 0px",
            borderBottom: "1px solid",
            borderColor:
              "var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))))",
          }}
        >
          {state.cart?.map((e, i) => {
            return (
              <IonItem
                style={{
                  "--inner-padding-end": "0",
                  "--inner-padding-start": "15px",
                }}
              >
                <div
                  className="p-2 flex-md-row align-items-center row position-relative"
                >
                  <IonIcon
                    onClick={() => {
                      if (state.cart) {
                        let tempCart = state.cart;
                        tempCart.splice(i, 1);
                        setState((prev) => ({
                          ...prev,
                          ...{ cart: tempCart },
                        }));
                      }
                    }}
                    id={"remove-cart-" + i}
                    color="danger"
                    style={{
                      marginLeft: "5px",
                      fontSize: "25px",
                      position: "absolute",
                      width: "25px",
                      right: "0",
                      top: "4px",
                      cursor: "pointer",
                      zIndex: 1,
                    }}
                    src={closeCircleOutline}
                  />
                  <div className="col-md-12 card-footer px-2 px-md-5 py-2 py-md-0 position-relative">
                    <div className="row">
                      <div className="col-auto">
                        <a>
                          <span className="mb-0 d-inline-block text-gray-800">
                            {e.category}
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="position-relative">
                      <a className="d-block stretched-link">
                        <h3 className="me-md-6 me-lg-10 me-xl-4 mb-2">
                          {/* Trygonometria - pojedynczy dział */}
                          {e.name}
                        </h3>
                      </a>
                      <ul className="nav mx-n3 mb-3">
                        <li className="nav-item px-3">
                          <div className="d-flex align-items-center">
                            <div className="me-2 d-flex text-secondary icon-uxs">
                              <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M17.1947 7.06802L14.6315 7.9985C14.2476 7.31186 13.712 6.71921 13.0544 6.25992C12.8525 6.11877 12.6421 5.99365 12.4252 5.88303C13.0586 5.25955 13.452 4.39255 13.452 3.43521C13.452 1.54098 11.9124 -1.90735e-06 10.0197 -1.90735e-06C8.12714 -1.90735e-06 6.58738 1.54098 6.58738 3.43521C6.58738 4.39255 6.98075 5.25955 7.61414 5.88303C7.39731 5.99365 7.1869 6.11877 6.98502 6.25992C6.32752 6.71921 5.79178 7.31186 5.40787 7.9985L2.8447 7.06802C2.33612 6.88339 1.79688 7.26044 1.79688 7.80243V16.5178C1.79688 16.8465 2.00256 17.14 2.31155 17.2522L9.75312 19.9536C9.93073 20.018 10.1227 20.0128 10.2863 19.9536L17.7278 17.2522C18.0368 17.14 18.2425 16.8465 18.2425 16.5178V7.80243C18.2425 7.26135 17.704 6.88309 17.1947 7.06802ZM10.0197 1.5625C11.0507 1.5625 11.8895 2.40265 11.8895 3.43521C11.8895 4.46777 11.0507 5.30792 10.0197 5.30792C8.98866 5.30792 8.14988 4.46777 8.14988 3.43521C8.14988 2.40265 8.98866 1.5625 10.0197 1.5625ZM9.23844 18.1044L3.35938 15.9703V8.91724L9.23844 11.0513V18.1044ZM10.0197 9.67255L6.90644 8.54248C7.58164 7.51892 8.75184 6.87042 10.0197 6.87042C11.2875 6.87042 12.4577 7.51892 13.1329 8.54248L10.0197 9.67255ZM16.68 15.9703L10.8009 18.1044V11.0513L16.68 8.91724V15.9703Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div className="font-size-sm">3 lekcje</div>
                          </div>
                        </li>
                        <li className="nav-item px-3">
                          <div className="d-flex align-items-center">
                            <div className="me-2 d-flex text-secondary icon-uxs">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.3164 4.20996C13.985 4.37028 13.8464 4.76904 14.0067 5.10026C14.4447 6.00505 14.6667 6.98031 14.6667 8C14.6667 11.6759 11.6759 14.6667 8 14.6667C4.32406 14.6667 1.33333 11.6759 1.33333 8C1.33333 4.32406 4.32406 1.33333 8 1.33333C9.52328 1.33333 10.9543 1.83073 12.1387 2.77165C12.4259 3.00098 12.846 2.95296 13.0754 2.66471C13.3047 2.37663 13.2567 1.95703 12.9683 1.72803C11.5661 0.613607 9.8016 0 8 0C3.58903 0 0 3.58903 0 8C0 12.411 3.58903 16 8 16C12.411 16 16 12.411 16 8C16 6.77767 15.7331 5.60628 15.2067 4.51969C15.0467 4.18766 14.6466 4.04932 14.3164 4.20996Z"
                                  fill="currentColor"
                                />
                                <path
                                  d="M7.99967 2.66663C7.63167 2.66663 7.33301 2.96529 7.33301 3.33329V7.99996C7.33301 8.36796 7.63167 8.66663 7.99967 8.66663H11.333C11.701 8.66663 11.9997 8.36796 11.9997 7.99996C11.9997 7.63196 11.701 7.33329 11.333 7.33329H8.66634V3.33329C8.66634 2.96529 8.36768 2.66663 7.99967 2.66663Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div className="font-size-sm">3h 12m</div>
                          </div>
                        </li>
                        <li className="nav-item px-3">
                          <div className="d-flex align-items-center">
                            <div className="me-2 d-flex text-secondary icon-uxs">
                              <IonIcon color="secondary" src={pricetag} />
                            </div>
                            <div
                              className="font-size-sm"
                              style={{
                                fontWeight: "700",
                                color: "var(--ion-color-secondary)",
                              }}
                            >
                              399,00 zł
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </IonItem>
            );
          })}

          {!Array.isArray(state.cart) || !state.cart.length ? (
            <h3
              style={{
                marginBottom: "25px",
                marginTop: "15px",
                textAlign: "center",
                color: "var(--ion-color-medium)",
              }}
            >
              Brak kursów w koszyku
            </h3>
          ) : (
            <>
              <IonItemDivider style={{ minHeight: "1px" }} />
              <IonItem style={{ "--min-height": "0" }}>
                <IonLabel
                  color="secondary"
                  style={{
                    textAlign: "center",
                    fontWeight: 500,
                    marginBottom: 0,
                    fontSize: "18px",
                  }}
                >
                  Razem do zapłaty
                </IonLabel>
              </IonItem>
              <IonItem style={{ "--min-height": "0" }}>
                <IonLabel
                  color="secondary"
                  style={{
                    textAlign: "center",
                    fontWeight: 500,
                    marginTop: 0,
                    fontSize: "19px",
                  }}
                >
                  <strong>100,00 zł</strong>
                </IonLabel>
              </IonItem>
              <IonItemDivider style={{ minHeight: "1px" }} />
              <Button
                fontSize={19}
                onClick={async () => {
                  const el = document.getElementById("cart-menu") as any;

                  if (el) {
                    el.close();
                  }
                  navigate("/cart", "forward", "push");
                }}
                style={{
                  width: "100%",
                  padding: "4px 10px",
                  fontWeight: "600",
                  margin: "7px 0",
                }}
                color="sucess"
                label="Przejdź do płatności"
                fill="clear"
              />
            </>
          )}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default MenuCart;
