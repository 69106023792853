import { localePl, Popup, setOptions } from "@mobiscroll/react";
import React, {
  createRef,
  MouseEvent,
  ReactNode,
  Ref,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonButtons,
  IonButton,
  IonIcon,
  IonBackButton,
  isPlatform,
  IonRouterLink,
} from "@ionic/react";

import { appsOutline, arrowBack, chevronBackOutline, reorderFourOutline } from "ionicons/icons";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router";

import './CustomHeaderFade.css'
import CustomerHeaderDesktop from "./CustomHeaderDesktop";

setOptions({
  theme: "ios",
  themeVariant: "light",
  locale: localePl,
});

type Props = {
  children?: ReactNode,
  title?: string;
  mode?: "ios" | "md";
  logo?: boolean;
  arrow?: boolean;
  long?: ReactNode;
  extended?: ReactNode;
  position?: "fixed";
  transformY?: number;
  extraButtons?: ReactNode;
  segment?: ReactNode;
  ionHeaderRef?: RefObject<HTMLIonHeaderElement>;
  page?: "Strona główna" | "Nasze kursy" | "Korepetycje" | undefined;
};

const CustomHeaderFade: React.FC<Props> = ({
  children,
  title,
  mode = "md",
  long,
  position,
  transformY,
  extraButtons,
  segment,
  ionHeaderRef,
  page,
  arrow = false,
  logo = false,
  extended = true,
}) => {
  const [hide, setHide] = useState(true);

  // useEffect(() => {
  //   setHide(true);

  //   setTimeout(() => {
  //     setHide(false);
  //   }, 150);
  // }, []);

  const history = useHistory();

  const toolbarDesktopRef = useRef<HTMLIonToolbarElement>(null);

  useEffect(() => {
    if(toolbarDesktopRef.current)
    {
      const toolbarBG = toolbarDesktopRef.current.shadowRoot?.querySelector(".toolbar-background");
      if(toolbarBG)
      {
        const _toolbarBG = toolbarBG as HTMLDivElement;
        _toolbarBG.style.transition = "ease-in-out 400ms all";
      }
    }
  }, [toolbarDesktopRef.current])

  useIonViewDidEnter(() => {

    if(toolbarDesktopRef.current)
    {
      const toolbarBG = toolbarDesktopRef.current.shadowRoot?.querySelector(".toolbar-background");
      if(toolbarBG)
      {
        const _toolbarBG = toolbarBG as HTMLDivElement;
        _toolbarBG.style.transition = "ease-in-out 400ms all";
      }
    }

  })

  return (
    <IonHeader
      ref={ionHeaderRef}
      style={{
        position: position,
        // transform: "translate3d(0, " + transformY + "px, 0)",
        willChange: "transform",
        transition: "transform 150ms cubic-bezier(0.56, 0.71, 0.91, 1) 0s"
      }}
      collapse="fade"
      translucent={isPlatform("mobile")}
      mode={isPlatform("mobile") ? mode : "ios"}
      className={
        (long ? "fade-header-long-container " : "") +
        (arrow ? "fade-header-arrow " : "") +
        (isPlatform("mobile") ? "" : "desktop-header ")
      }
    >
      <IonToolbar ref={toolbarDesktopRef} >
        {isPlatform("mobile") ? (
          <>
            {arrow ? (
              <IonButtons slot="start">
                <IonButton onClick={() => history.goBack()}>
                  <IonIcon slot="icon-only" icon={chevronBackOutline} />
                </IonButton>
              </IonButtons>
            ) : (
              <></>
            )}
            <IonTitle>
              <div
                className={"fade-header " + (long ? "fade-header-long" : "")}
              >
                {logo ? (
                  <IonRouterLink routerLink="/">
                    <a className="navbar-brand me-0">
                      <img
                        src="./assets/img/brand-gigas.svg"
                        className="navbar-brand-img"
                        alt="..."
                        style={{ paddingTop: "5px", paddingBottom: "5px" }}
                      />
                    </a>
                  </IonRouterLink>
                ) : children ? (
                  children
                ) : (
                  title
                )}
              </div>
            </IonTitle>

            {extraButtons}

            {extended ? (
              <IonButtons slot="end">
                <IonButton
                  onClick={() => {
                    const el = document.getElementById("mainMenu") as any;
                    console.log(el);
                    if (el) {
                      el.open();
                    }
                  }}
                >
                  <IonIcon slot="icon-only" icon={reorderFourOutline} />
                </IonButton>{" "}
              </IonButtons>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div
            style={{
              marginTop: "0px",
              marginBottom: "5px",
            }}
          >
            <CustomerHeaderDesktop page={page} />
          </div>
        )}
      </IonToolbar>
      {segment}
    </IonHeader>
  );
};

export default CustomHeaderFade;
