import {
  IonItem,
  IonLabel,
  IonInput,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonGrid,
  IonCol,
  IonRow,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonSegment,
  IonSegmentButton,
  IonRadioGroup,
  IonRadio,
  IonAccordionGroup,
  IonList,
  IonAccordion,
  IonButtons,
  IonIcon,
  IonButton,
  IonBadge,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPopover,
  IonRippleEffect,
  IonAvatar,
  IonImg,
  IonicSlides,
  IonModal,
  IonItemDivider,
  IonCheckbox,
} from "@ionic/react";

import {
  Datepicker,
  Page,
  setOptions,
  localePl,
  Input,
  Popup,
  Select,
  Switch,
} from "@mobiscroll/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  RefObject,
} from "react";

import Button from "./../../components/Ebert/Button";

import { isPlatform, ScrollDetail } from "@ionic/core";

import MobiInput from "../../components/Mobi/MobiInput";

import "./Status.scss";
import Footer from "../../components/Footer";

import CustomHeaderFade from "../../components/CustomHeaderFade";
import MobiTextarea from "../../components/Mobi/MobiTextarea";
import {
  arrowBack,
  arrowBackCircle,
  arrowBackCircleOutline,
  arrowBackOutline,
  arrowForward,
  calendarOutline,
  cartOutline,
  chevronDown,
  chevronUp,
  contractOutline,
  exitOutline,
  home,
  layersOutline,
  libraryOutline,
  personCircle,
  pricetagOutline,
  star,
  timeOutline,
  trendingDown,
  trendingUp,
} from "ionicons/icons";
import HeaderToolbarMobile from "../../components/Header/HeaderToolbarMobile";

import Chemistry from "../svg/chemia.svg";
import Biology from "../svg/biologia.svg";

import { Pagination, Lazy, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "@ionic/react/css/ionic-swiper.css";

setOptions({
  theme: "ios",
  themeVariant: "light",
});

const Home: React.FC = () => {
  let _localePl = localePl;
  _localePl.setText = "Ustaw";

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const [scheduleOpenIndex, setScheduleOpenIndex] = useState<number[]>([]);

  const ionPopoverRef = useRef<HTMLIonPopoverElement>(null);

  const [segmentValue, setSegmentValue] = useState("buy");

  const segmentContainerRef = useRef<HTMLDivElement>(null);
  const ionHeaderRef = useRef<HTMLIonHeaderElement>(null);

  const [data, setData] = useState<string[]>([]);
  const [isInfiniteDisabled, setInfiniteDisabled] = useState(false);

  const [choosenPrzedmiot, setChoosenPrzedmiot] = useState(
    isPlatform("mobile") ? "Dowolny przedmiot" : "Dowolny"
  );

  const [przedmiotySegmentWidth, setPrzedmiotySegmentWidth] = useState(235);

  const [lastTransformY, setLastTransformY] = useState(0);
  const [transformY, setTransformY] = useState(0);

  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [appointmentModalCheck, setAppointmentModalCheck] = useState(false);

  useEffect(() => {
    switch (choosenPrzedmiot) {
      case "Dowolny przedmiot":
        setPrzedmiotySegmentWidth(235);
        break;
      case "Chemia":
        setPrzedmiotySegmentWidth(135);
        break;
      case "Biologia":
        setPrzedmiotySegmentWidth(145);
        break;
      default:
        console.log("");
    }
  }, [choosenPrzedmiot]);

  const pushData = () => {
    const max = data.length + 3;
    const min = max - 3;
    const newData = [];
    for (let i = min; i < max; i++) {
      newData.push("Item" + i);
    }

    setData([...data, ...newData]);
  };

  const loadData = (ev: any) => {
    setTimeout(() => {
      pushData();
      console.log("Loaded data");
      ev.target.complete();
      if (data.length == 1000) {
        setInfiniteDisabled(true);
      }
    }, 5000);
  };

  useIonViewWillEnter(() => {
    pushData();

    segmentContainerRef.current
      ?.querySelectorAll(".segment-single")
      .forEach((_e) => {
        const parsed = _e as HTMLDivElement;
        parsed.style.display = "";
      });

    const e = segmentContainerRef.current?.querySelector(
      ".segment-single" + "." + segmentValue
    ) as HTMLDivElement;

    console.log(e);

    if (e) {
      e.style.display = "block";
    }
  });

  return (
    <IonPage>
      <CustomHeaderFade
        position="fixed"
        // transformY={transformY}
        ionHeaderRef={ionHeaderRef}
        title="Status korepetycji"
        arrow
        extraButtons={<HeaderToolbarMobile />}
      />

      <IonContent
        id="corepetitions-status-page-controller"
        className="background-lightgrey slide-transition-leave"
        scrollEvents={false}
        // onTouchStart={(e) => {
        //   setLastTransformY(e.changedTouches[0].clientY);
        // }}
        // onTouchMove={(e) => {
        //   if(isPlatform("mobile") || isPlatform("tablet"))
        //   {
        //     const distance = e.touches[0].clientY - lastTransformY;
        //     if(transformY + distance <= 0 && transformY + distance >= -56)
        //     {
        //       setTransformY(transformY + distance);
        //     }
        //     setLastTransformY(e.changedTouches[0].clientY);
        //   }
        // }}
        onIonScrollEnd={(e) => {
          const target = e.target as any;
          if (target && ionHeaderRef.current) {
            if (target.detail) {
              if (target.detail.scrollTop == 0) {
                setTransformY(0);
                ionHeaderRef.current.style.transform =
                  "translate3d(0px, 0px, 0px)";
              }
            }
          }
        }}
        onIonScroll={(e) => {
          const toolbarHeight = -52;
          if (
            (isPlatform("mobile") || isPlatform("tablet")) &&
            ionHeaderRef.current
          ) {
            const distance = -1 * (e.detail.scrollTop / 2 - lastTransformY);
            if (transformY + distance >= 1 || transformY + distance <= -1) {
              if (
                Math.round(transformY + distance) >= 1 ||
                Math.round(transformY + distance) <= -1
              ) {
                if (
                  transformY + distance <= 0 &&
                  transformY + distance >= toolbarHeight
                ) {
                  setTransformY(transformY + distance);
                  ionHeaderRef.current.style.transform =
                    "translate3d(0px, " +
                    Math.round(Number(transformY) + Number(distance)) +
                    "px, 0px)";
                } else if (
                  transformY + distance < toolbarHeight &&
                  transformY != toolbarHeight
                ) {
                  setTransformY(toolbarHeight);
                  ionHeaderRef.current.style.transform =
                    "translate3d(0px, " + toolbarHeight + "px, 0px)";
                } else if (transformY + distance > 0 && transformY != 0) {
                  setTransformY(0);
                  ionHeaderRef.current.style.transform =
                    "translate3d(0px, 0px, 0px)";
                }
              }
            }
            setLastTransformY(e.detail.scrollTop / 2);
          }
        }}
      >
        <div className="container mb-6 mb-md-10 mt-3 container-filter">
          <div className="row appointment-button-back-container"></div>
          <div className="appointment-modal-content">
            <div className="row justify-content-start list-item">
              <div className="col-12 col-md-6 d-flex">
                <IonAvatar>
                  <IonImg src="/assets/img/avatars/avatar-1.jpg" />
                </IonAvatar>
                <div className="list-description">
                  <h2 className="name">Jan Kowalski</h2>
                  <p className="category">MATEMATYKA</p>
                </div>
              </div>
              <div className="col-12 col-md-6 reservation-container mt-4 mt-md-0">
                <h2 className="mb-0">
                  Status korepetycji
                </h2>
                <h1
                  className="mb-0"
                  style={{
                    color: "var(--ion-color-success)",
                  }}
                >
                  <strong>Zarezerwowano i opłacono</strong>
                </h1>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-6">
              <div className="mt-4 mb-1">
              <IonList lines="full" className="description">
                <IonItem>
                  <IonLabel color="secondary">Przedmiot</IonLabel>
                  <IonLabel color="secondary">
                    <strong>Matematyka</strong>
                  </IonLabel>
                  <IonIcon color="secondary" src={libraryOutline} />
                </IonItem>
                <IonItem>
                  <IonLabel color="secondary">Cena za godzinę</IonLabel>
                  <IonLabel color="secondary">
                    <strong>100,00 zł</strong>
                  </IonLabel>
                  <IonIcon color="secondary" src={pricetagOutline} />
                </IonItem>
                <IonItem>
                  <IonLabel color="secondary">Termin</IonLabel>
                  <IonLabel color="secondary">
                    <strong>26.02.2022r.</strong>
                  </IonLabel>
                  <IonIcon color="secondary" src={calendarOutline} />
                </IonItem>
                <IonItem lines="none">
                  <IonLabel color="secondary">Godzina</IonLabel>
                  <IonLabel color="secondary">
                    <strong>12:00 - 13:00</strong>
                  </IonLabel>
                  <IonIcon color="secondary" src={timeOutline} />
                </IonItem>
              </IonList>
            </div>
              </div>
              <div className="col-12 col-lg-6 px-7 px-md-3" style={{ margin: "auto" }}>
                <h3><strong>
                Pamiętaj!
                </strong></h3>
                <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit itaque minus culpa, suscipit iure enim est eum cum dolores ipsa eos cupiditate hic quia dolore voluptate pariatur similique autem! Eum.
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur, dolorum cum. Nisi sit laborum in culpa numquam, rerum ex commodi voluptatibus porro id illum, quia, eligendi molestias. Neque, laborum voluptatibus.
              </p>
              </div>
              
            </div>

            

            <div className="col-12 mt-5">
              <div className="row">
                <div className="col-12 col-lg-6 px-7">
                  <h3>Dane kontaktowe ucznia</h3>
                  <div className="row mt-2">
                    <div className="col-12 col-md-6">
                      <MobiInput label="Imię" required disabled />
                    </div>
                    <div className="col-12 col-md-6 mt-4 mt-md-0">
                      <MobiInput label="Nazwisko" required disabled />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <MobiInput label="Adres email" required disabled />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <MobiInput label="Numer telefonu" required disabled />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 px-7 px-md-3" style={{ margin: "auto" }} >
                  <h3 className="mt-7">
                    Dodatkowe informacje dla korepetytora
                  </h3>
                  <div className="row mt-4">
                    <div className="col-12">
                      <MobiTextarea disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default Home;
